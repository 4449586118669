import { Call, ApiUrl, ParseGeneralResponse } from "./ApiUtils";

export function studyNameList(data) {
  const url = ApiUrl("/study/names");
  return Call(
    "get",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function instructionsGetList(data) {
  const url = ApiUrl("/instruction/list");
  return Call(
    "get",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function addInstruction(data) {
  const url = ApiUrl("/instruction/create_instruction");
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
    true
  );
}

export function editINstructions(data) {
  const url = ApiUrl("/instruction/update_instruction");
  return Call(
    "put",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
    true
  );
}
export function deleteInstruction(data) {
  const url = ApiUrl(`/instruction/delete_instruction/${data.id}`);
  return Call("delete", url, data, ParseGeneralResponse, {}, true);
}
