import { Call, ApiUrl, ParseGeneralResponse } from "../Components/ApiUtils";

export function myprofile(data) {
  const url = ApiUrl("/study/list");
  return Call(
    "get",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}
export function deleteStudy(data) {
  const url = ApiUrl(`/study/delete_study/${data}`);
  return Call("delete", url, data, ParseGeneralResponse, {}, true);
}

export function addStudy(data) {
  const url = ApiUrl("/study/create_study");
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
    true
  );
}
export function editStudy(data) {
  const url = ApiUrl("/study/update_study");
  return Call(
    "put",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
    true
  );
}
export function brandsData(data) {
  const url = ApiUrl("/study/brands?search=");
  return Call(
    "get",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
    true
  );
}

export function loginModerator(data) {
  const url = ApiUrl("/auth/signIn");
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
    true
  );
}

export function moderatorImages(data) {
  const url = ApiUrl("/auth/images");
  return Call(
    "get",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}
export function moderatorData(data) {
  const url = ApiUrl("/auth/moderators");
  return Call(
    "get",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}
export function fakeIMages(data) {
  const url = "https://jsonplaceholder.typicode.com/photos";
  return Call(
    "get",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function individualUserReport(data) {
  const url = ApiUrl("/report/users");
  return Call(
    "get",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
    true
  );
}
