import React from "react";
import { Layout, Space } from "antd";
import NavBar from "../NavBar/NavBar";
import { Outlet } from "react-router-dom";
import HeadingBar from "../SpencerHall/HeadingBar/HeadingBar";

const { Header, Content } = Layout;

const LayoutIconnectComponent = () => (
  <Layout style={{ height: "100vh" }}>
    <Layout>
      <Header>
        <NavBar />
      </Header>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  </Layout>
);
export default LayoutIconnectComponent;
