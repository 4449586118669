import { Call, ApiUrl, ParseGeneralResponse } from "./ApiUtils";

export function participantLogin(data) {
  const url = ApiUrl("/auth/login");
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
    true
  );
}
export function getStudyImages(data) {
  const url = ApiUrl(`/imageSelections/studyImages?study_id=${data.id}`);
  return Call(
    "get",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function createBrandImageSelection(data) {
  const url = ApiUrl("/imageSelections/createBrandImageSelection");
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
    true
  );
}

export function getIndividualBrandImages(data) {
  const url = ApiUrl(
    `/imageSelections/brandImages?study_id=${data.id}&brandId=${data.individual_brand_id}&userId=${data.user_id_data}`
  );
  return Call(
    "get",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function BrandSaveImageSelection(data) {
  const url = ApiUrl("/imageSelections/saveImageResponse");
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
    true
  );
}
export function getStudyQuestions(data) {
  const url = ApiUrl(
    `/questions/studyQuestions?study_id=${data.studyid}&type=1&user_id=${data.user_id_data}&brand_id=${data.brandId}`
  );
  return Call(
    "get",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

export function ExplanationQuestionResponse(data) {
  const url = ApiUrl("/questionResponses/saveQuestionResponse");
  return Call(
    "post",
    url,
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: { data: "" },
    },
    true
  );
}

export function getComparisonQuestionsAndImages(data) {
  const url = ApiUrl(
    `/questions/studyQuestions?study_id=${data.studyId}&type=2&user_id=${data.user_id_data}&brand_id=${data.brandId}`
  );
  return Call(
    "get",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}

// ---------Study Report ------------------------------
export function getStudyReport(data) {
  const url = ApiUrl(`/study/report/${data.id}`);
  return Call(
    "get",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}
export function individualUsergetStudyReport(data) {
  const url = ApiUrl(`/report/user/${data.id}?studyId=${data.studyId}`);
  return Call(
    "get",
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { data: "" } },
    true
  );
}
