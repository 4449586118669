import React from "react";

import "../StudyURL/studyurl.css";
export default function StudyUrl(props) {
  const handleClick = (props) => {
    window.open(`/signIn/${props.ele.id}`);
  };
  return (
    <div>
      <button className="studyUrl" onClick={() => handleClick(props)}>
        Click Here
      </button>
    </div>
  );
}
