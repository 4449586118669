import React from "react";
import img1 from "../../images/img1.jpg";
import img2 from "../../images/img2.jpg";
import img3 from "../../images/img3.jpg";
import img4 from "../../images/img4.jpg";
import img5 from "../../images/img5.jpg";
import img0 from "../../images/img0.jpg";
import "../CarouselComponent/carousel.css";
import LandingPageTheory from "../LandingPageTheory/LandingPageTheory";
const Gallery = () => {
  return (
    <div className="carouselContainer">
      <div className="carouselMargin">
        <img src={img3} width="16.66%" />
        <img src={img1} width="16.66%" />
        <img src={img5} width="16.66%" />
        <img src={img2} width="16.66%" />
        <img src={img0} width="16.66%" />
        <img src={img4} width="16.66%" />
      </div>
      <LandingPageTheory />
    </div>
  );
};
export default Gallery;
