import React, { useState } from "react";
import Hermione from "../../../../images/Hermione.png";
import "../Header/header.css";
import { Avatar } from "antd";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dropdown } from "antd";
import { logout } from "../../../redux/actions/IcAction";
export default function HeaderComponent() {
  const [auth, setAuth] = useState(false);
  const first_name = localStorage.getItem("first_name");

  const first_letter = first_name?.charAt(0)?.toUpperCase();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logoutAction = () => {
    setAuth(true);
    // localStorage.removeItem("role");
    dispatch(logout());
  };
  if (auth) {
    navigate("/");
  }
  const items = [
    {
      label: (
        <button onClick={logoutAction} className="headerlogoutbtn">
          LOGOUT
        </button>
      ),
      key: "0",
    },
  ];
  return (
    <div className="d-flex justify-content-end  " style={{ marginTop: "18px" }}>
      {/* <NotificationsOutlinedIcon
        sx={{
          fontSize: "35px",
          color: " #07485E",
          marginTop: "5px",
        }}
      /> */}

      <Avatar className="avatar">{first_letter}</Avatar>
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
        className="logoutbtnheader"
      >
        <KeyboardArrowDownIcon
          sx={{
            fontSize: "20px",
            color: "#9e9696",
            marginTop: "15px",
          }}
        />
      </Dropdown>
    </div>
  );
}
