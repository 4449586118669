import React from "react";
import LoginPage from "../Form/Form";
import "../LandingPageTheory/LandingPageTheory.css";
export default function LandingPageTheory() {
  return (
    <div className="container-fluid ">
      <div className="col-md-12 col-sx-12 TheoryDivision">
        <p className="blackHeading">
          This is a fun image association exercise where you will review a set
          of symbolic images. These images should evoke feelings and
          impressions, rather than being taken literally, to help capture the
          associations you may have for a brand, a product category, or an
          experience.
          <br /> For example, your image of Godiva chocolate might be luxurious
          (like silk), indulgent (like extra whipped topping on a piece of pie),
          special (like gold or diamonds) while your image of Hershey's
          chocolate could be All American (like a red, white & blue flag),
          everyday (like a hamburger), convenient (like eggs in a carton). They
          are both chocolates, but each brand has its own image that makes you
          ‘feel’ a different way about them.
        </p>

        <div className="blackHeading4">
          Enter your Name in the boxes below to continue.
        </div>
      </div>
      <LoginPage />
    </div>
  );
}
