import React from "react";
import Gallery from "../CarouselComponent/CarouselImages";
import NavBar from "../NavBar/NavBar";
export default function FinalComponents() {
  return (
    <div>
      <Gallery />
    </div>
  );
}
