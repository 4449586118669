import React, { useEffect, useState } from "react";
import "../userSelectedImages/userSelected.css";
import netLoader from "../../../images/loading-slow-net.gif";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createBrandImageSelection,
  getIndividualBrandImages,
} from "../../participants.js";
export default function SelectedImages() {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { propsData } = location.state || {};
  const [check, setCheck] = useState([]);

  const { id } = useParams();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const user_id_data = sessionStorage.getItem("loginUserId");
  const [loading, setLoading] = useState(false);
  const [brand, setBrand] = useState();

  useEffect(() => {
    setIsLoading(true);
    setBrand({
      name: propsData[0].name,
      id: propsData[0].id,
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      if (brand?.id) {
        const individual_brand_id = brand?.id;
        setIsLoading(true);
        const response = await getIndividualBrandImages({
          id,
          individual_brand_id,
          user_id_data,
        });
        if (response) {
          if (response.success === true) {
            if (response.data.brandImages.length === 0) {
              const brandId = propsData.map((item) => item.id);
              const index = brandId.indexOf(brand.id);
              if (index < brandId.length) {
                setBrand(propsData[index + 1]);
                setImages(response.data.brandImages);

                if (
                  index >= brandId.length - 1 &&
                  response.data.brandImages.length === 0
                ) {
                  navigate(`/explanation/${id}`, {
                    state: { propsData: propsData },
                    replace: true,
                  });
                  return;
                }
              }
            } else if (response.data.brandImages.length <= 5) {
              setIsLoading(true);

              let finalImages = response.data.brandImages.map((ele) => ele.id);
              const data = await createBrandImageSelection({
                brand_id: brand.id,
                study_id: id,
                imageIds: finalImages,
                user_id: user_id_data,
              });
              if (data) {
                if (response.success === true) {
                  const brandId = propsData.map((item) => item.id);
                  const index = brandId.indexOf(brand.id);
                  if (index < brandId.length) {
                    setBrand(propsData[index + 1]);
                    setImages(response.data.brandImages);
                    setIsLoading(false);
                  }
                  if (index >= brandId.length - 1) {
                    navigate(`/explanation/${id}`, {
                      state: { propsData: propsData },
                      replace: true,
                    });
                    return;
                  }
                  setIsLoading(false);
                }
              }
            } else {
              if (loading === false) {
                setLoading(true);
                setImages(response.data.brandImages);
                setLoading(false);
              }
              setIsLoading(false);
            }
          }
        }
      }
    };
    fetchData();
  }, [brand?.id]);

  const handleCheckboxChange = (e) => {
    let images_array = [...check];

    if (e.target.checked) {
      if (images_array.length < 5) {
        images_array = [...check, parseInt(e.target.value)];
      } else {
        toast.error("Please select only 5 images", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        e.target.checked = false;
      }
    } else {
      images_array.splice(check.indexOf(e.target.value), 1);
    }
    setCheck(images_array);
    setIsButtonDisabled(images_array.length < 5);
  };

  const createBrandImageSelectionHandle = async () => {
    setIsLoading(true);
    if (loading === false) {
      setLoading(true);
      const response = await createBrandImageSelection({
        brand_id: brand.id,
        study_id: id,
        imageIds: check,
        user_id: user_id_data,
      });
      if (response) {
        setLoading(false);
        setCheck([]);
        const checkboxes = document.querySelectorAll(".checkboxSelected");
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
        setIsLoading(false);
        setIsButtonDisabled(true);
      }
    }

    const brandId = propsData.map((item) => item.id);
    const index = brandId.indexOf(brand.id);

    if (index < brandId.length) {
      setBrand(propsData[index + 1]);
    } else {
      navigate(`/explanation/${id}`, {
        state: { propsData: propsData },
        replace: true,
      });
    }

    if (index >= brandId.length - 1) {
      navigate(`/explanation/${id}`, {
        state: { propsData: propsData },
        replace: true,
      });
      return;
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loading-container">
          <img className="loader_style" src={netLoader} />
        </div>
      ) : (
        <div>
          <div>
            <p className="please_select">
              Please select the <span className="five_images">5 images </span>
              that most strongly match YOUR IMAGE of{" "}
              <span className="brandsForSelection">{brand?.name}.</span>
            </p>
            <div className="imagesAndCheckbox">
              <div className="selectOverSelection">
                <div className="selectOverSelectionOne">
                  {images?.map((imag) => {
                    return (
                      <div>
                        <lable>
                          <input
                            type="checkbox"
                            className="checkboxSelected"
                            value={imag.id}
                            onChange={handleCheckboxChange}
                          ></input>
                          <div className="images_height_adjudsting">
                            <img
                              src={imag.url}
                              width="100%"
                              alt="img not found"
                              className="borderForImages"
                              onClick={() => {
                                const checkbox = document.querySelector(
                                  `input[value="${imag.id}"]`
                                );
                                checkbox.checked = !checkbox.checked;
                                handleCheckboxChange({ target: checkbox });
                              }}
                            />
                          </div>
                        </lable>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <button
              onClick={createBrandImageSelectionHandle}
              className={
                loading
                  ? "selectOverSelectionNextBtn_loading"
                  : "selectOverSelectionNextBtn"
              }
              disabled={isButtonDisabled}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
