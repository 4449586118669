import React from "react";
import girl_illustration from "../../../images/Girls_illustration.png";
import "../ThankyouPage/thankyou.css";

export default function Thankyou() {
  // const handleOk = () => {
  //   sessionStorage.removeItem("loginUserId");
  //   sessionStorage.removeItem("participant_role");
  //   const newWindow = window.open("", "_self");
  //   newWindow.close();
  //   window.location.href = "about:blank";
  //   window.open("", "_self").close();
  //   window.close();
  //   window.opener.close();
  // };

  return (
    <div>
      <div className="girl_illustration">
        <img src={girl_illustration} alt="Girl illustration"></img>
      </div>
      <p className="thanks_your">
        Thanks, your responses have been submitted.<br></br> Please close this
        window and return to the <br></br>Sounding Board
      </p>
      {/* <div className="thanks_margin">
        <button className="ok_Btn" onClick={handleOk}>
          Close Window
        </button>
      </div> */}
    </div>
  );
}
