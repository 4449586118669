import { Navigate, Outlet } from "react-router-dom";

const ParticipantProtected = () => {
  const data = sessionStorage.getItem("participant_role");
  const id = sessionStorage.getItem("studyId");

  return data ? (
    <Navigate to={`/Users/${window.location.href.split("/").pop()}`} />
  ) : (
    <Outlet />
  );
};

export default ParticipantProtected;
