import React, { useState } from "react";
import { individualUsergetStudyReport } from "../../participants";
import jsPDF from "jspdf";
import { LoadingOutlined } from "@ant-design/icons";
const removeHTMLTags = (str) => {
  if (!str || typeof str !== "string") return "";
  return str.replace(/<[^>]*>/g, "");
};
const boldFont = {
  fontFamily: "Helvetica-Bold",
  fontStyle: "bold",
  // ... other font properties
};

export default function ReportPdf({ ele }) {
  const [loading, setLoading] = useState(false);

  const fetchImages = async (image) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/image?url=${image}`,
      {
        method: "GET",
        headers: {
          "client-id": `${process.env.REACT_APP_CLIENT_ID}`,
          "client-secret": `${process.env.REACT_APP_CLIENT_SECRET}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Failed to fetch image (${response.status}): ${response.url}`
      );
    }

    const contentType = response.headers.get("content-type");

    const imageBlob = await response.blob();

    if (contentType && contentType.startsWith("image/")) {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          const base64String = reader.result;
          resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(imageBlob);
      });
    } else {
      throw new Error("Response is not an image");
    }
  };

  const exportToPdf = async (data) => {
    let studyNameReport = data?.firstName;
    setLoading(true);
    const doc = new jsPDF();
    doc.addFont("path/to/helvetica-bold.ttf", "Helvetica-Bold", "bold"); // Load the bold font variant
    let y = 20; // Initial y-coordinate for text

    const heading = "SpencerHall                                   iConnect";
    const subHead = `${data?.firstName} ${data?.lastName},\n\n.`.toUpperCase();
    const content = `Study Name: ${data?.studyName},\n\nFirst Name: ${data?.firstName},\n\nLast Name: ${data?.lastName}`;

    const headingFontSize = 25; // Adjust the font size for the heading
    const headingFontColor = "#FFFFFF"; // Adjust the font color for the heading
    const headingBackgroundColor = "#112F54";
    const subheadingFontSize = 25; // Adjust the font size for the subheading
    const subheadingFontColor = "#964B00"; // Adjust the font color for the subheading
    const contentFontSize = 15; // Adjust the base font size for the content
    const maxQuestionLength = 120; // Adjust the maximum question length
    const maxResponseLength = 120; // Adjust the maximum response length

    doc.setFontSize(headingFontSize);
    doc.setTextColor(headingFontColor);
    doc.setFont("helvetica", "bold");
    const headingWidth =
      doc.getStringUnitWidth(heading) * headingFontSize * 0.35;
    const pageWidth = doc.internal.pageSize.width;
    const backgroundHeight = 15;
    doc.setFillColor(headingBackgroundColor); // Set the background color for the heading
    doc.rect(0, y - 20, pageWidth, backgroundHeight, "F");
    doc.text(heading, 20, 10);
    y += 5; // Adjust this value as needed for spacing
    doc.setFontSize(subheadingFontSize);
    doc.setTextColor(subheadingFontColor);
    doc.text(subHead, 70, y);
    y += 15;
    // doc.setFontSize(contentFontSize);
    // doc.setFont("Helvetica-normal");
    // doc.setTextColor("#000000"); // Reset font color to default
    // doc.text(content, 20, y);
    // y += 15; // Adjust this value as needed for spacing
    doc.setFontSize(contentFontSize);
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#000000");
    doc.text("Study Name:", 20, y);
    doc.setFont("helvetica", "normal");
    doc.text(data?.studyName, 60, y);

    y += 10; // Adjust this value as needed for spacing

    doc.setFontSize(contentFontSize);
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#000000");
    doc.text("First Name:", 20, y);
    doc.setFont("helvetica", "normal");
    doc.text(data?.firstName, 60, y);

    y += 10; // Adjust this value as needed for spacing

    doc.setFontSize(contentFontSize);
    doc.setFont("helvetica", "bold");
    doc.setTextColor("#000000");
    doc.text("Last Name:", 20, y);
    doc.setFont("helvetica", "normal");
    doc.text(data?.lastName, 60, y);

    let previousTopFiveBrandName = null;

    for (const brand of data.top5Images) {
      let x = 20; // Initial x-coordinate for the first image in each row
      // Initial y-coordinate for each row
      for (const ele of brand) {
        const brandName = `Top Five Images For ${ele.brandName}`;
        if (brandName !== previousTopFiveBrandName) {
          const brandNameLines = doc.splitTextToSize(brandName, 180);
          const brandNameHeight = brandNameLines.length * 10; // Adjust this value as needed for spacing
          y += brandNameHeight + 10; // Adjust this value as needed for spacing
          doc.setFontSize(contentFontSize);
          doc.setFont("helvetica", "bold");
          doc.setTextColor("#e4a41f");
          doc.text(brandNameLines, 20, y);
          y += 10; // Adjust this value as needed for spacing
          previousTopFiveBrandName = brandName;
        }

        const imageDataOne = await fetchImages(ele.imageUrl);

        doc.addImage(imageDataOne, "JPEG", x, y, 30, 30);

        x += 35; // Adjust this value as needed for spacing between images
      }
      y += 20; // Adjust this value as needed for spacing between brands
    }

    let previousBrandName = null;
    data.explantionQuestions.forEach((brand) => {
      brand.forEach((ele, index) => {
        const brandName = `Explanation Questions For ${ele.brandName}`;
        if (brandName !== previousBrandName) {
          const brandNameLines = doc.splitTextToSize(brandName, 180);
          const brandNameHeight = brandNameLines.length * 10; // Adjust this value as needed for spacing
          y += brandNameHeight + 10; // Adjust this value as needed for spacing
          if (y > doc.internal.pageSize.height - 20) {
            doc.addPage(); // Add a new page
            y = 20; // Reset the y-coordinate for the new page
          }
          doc.setFontSize(contentFontSize);
          doc.setFont("helvetica", "bold");
          doc.setTextColor("#e4a41f");
          doc.text(brandNameLines, 20, y);
          y += 10; // Adjust this value as needed for spacing
          previousBrandName = brandName;
        }
        const question = removeHTMLTags(`${index + 1}.${ele.question}`);
        const questionLines = doc.splitTextToSize(question, 170);
        const response = `${ele.response}`;
        const responseLines = doc.splitTextToSize(response, 170);

        if (y + responseLines.length * 10 > doc.internal.pageSize.height - 20) {
          doc.addPage(); // Add a new page
          y = 20; // Reset the y-coordinate for the new page
        }
        const questionHeight = doc.getTextDimensions(questionLines).h;
        const responseHeight = doc.getTextDimensions(responseLines).h;
        const totalHeight = Math.max(questionHeight, responseHeight);

        const spaceLeft = doc.internal.pageSize.height - y;

        if (totalHeight > spaceLeft) {
          doc.addPage();
          y = 20; // Reset y-coordinate for new page
        }

        const questionFontSize =
          question.length > maxQuestionLength
            ? contentFontSize - 2
            : contentFontSize;
        doc.setFontSize(contentFontSize - 2);
        doc.setTextColor("#000000");
        doc.setFont("helvetica", "bold");
        doc.text(questionLines, 20, y);
        y += questionHeight + 3; // Use questionHeight for consistent spacing
        const responseFontSize =
          response.length > maxResponseLength
            ? contentFontSize - 2
            : contentFontSize;

        doc.setFontSize(contentFontSize - 2);
        doc.setFont("helvetica", "normal");
        doc.setTextColor("#000000");
        doc.text(responseLines, 20, y);
        y += responseHeight + 5; // Use responseHeight for consistent spacing
        // Adjust this value as needed for spacing
      });
    });

    const spaceLeft = doc.internal.pageSize.height - y;
    const textHeight = doc.getTextDimensions(heading).h;

    if (textHeight > spaceLeft) {
      doc.addPage();
      y = 10; // Reset y-coordinate for new page
    }
    y += 15;
    if (data?.comparisonQuestions.length >= 1) {
      const compHeaidng = `Comparision Questions `;
      doc.setFontSize(contentFontSize);
      doc.setTextColor("#e4a41f");
      doc.setFont("helvetica", "bold");
      doc.text(compHeaidng, 20, y);
      y += 10;
    }

    data?.comparisonQuestions?.forEach((ele, index) => {
      const compquestion = removeHTMLTags(`${index + 1}.${ele?.question}`);
      const compquestionLines = doc.splitTextToSize(compquestion, 170);
      const compresponse = `${ele?.response}`;
      const compresponseLines = doc.splitTextToSize(compresponse, 170);

      const compquestionHeight = doc.getTextDimensions(compquestionLines).h;
      const compresponseHeight = doc.getTextDimensions(compresponseLines).h;
      const totalHeight = Math.max(compquestionHeight, compresponseHeight);

      const spaceLeft = doc.internal.pageSize.height - y;

      if (totalHeight > spaceLeft) {
        doc.addPage();
        y = 20; // Reset y-coordinate for new page
      }

      const compquestionFontSize =
        compquestion.length > maxQuestionLength
          ? contentFontSize - 2
          : contentFontSize;
      const compresponseFontSize =
        compresponse.length > maxResponseLength
          ? contentFontSize - 2
          : contentFontSize;
      doc.setFontSize(contentFontSize - 2);
      doc.setTextColor("#000000");
      doc.setFont("helvetica", "bold");
      doc.text(compquestionLines, 20, y);
      y += compquestionHeight + 5; // Use questionHeight for consistent spacing

      doc.setFontSize(contentFontSize - 2);
      doc.setFont("helvetica", "normal");
      doc.setTextColor("#000000");
      doc.text(compresponseLines, 20, y);
      y += compresponseHeight + 5; // Use responseHeight for consistent spacing

      // Adjust this value as needed for spacing
    });

    doc.save(`${studyNameReport}.pdf`, { return: "save" });
    setLoading(false);
  };

  const handleClickReports = () => {
    setLoading(true);
    individualUsergetStudyReport({ id: ele?.id, studyId: ele?.study?.id }).then(
      (response) => {
        exportToPdf(response?.data?.surveyUser);
      }
    );
  };

  return (
    <div>
      {loading ? (
        <span style={{ color: "blue", padding: "3px" }}>Downloading...</span>
      ) : (
        <button
          style={{
            border: "none",
            backgroundColor: "white",
            color: "blue",
            borderBottom: "1px solid blue",
            padding: "0px",
          }}
          onClick={handleClickReports}
        >
          Download Report
        </button>
      )}
    </div>
  );
}
