import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CloseIcon from "@mui/icons-material/Close";
import "../AddInstructions/previewinstruction.css";
import { Button } from "antd";
// --------------------------VIEWIMAGES POPUP--------------------------------------------
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ViewImages({ element, studyOptionUpdate }) {
  const instructionName = studyOptionUpdate?.find(
    (ele) => ele.id === element.studyId
  )?.name;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button
        className="view_instruction_btn_contentComponent"
        onClick={handleClickOpen}
      >
        <span style={{ color: "#da8517" }}>Preview</span>
      </button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "690px",
              minHeight: "400px",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{
            background: "#093975",
            color: "white",
            fontFamily: "RaleWay",
            fontWeight: "600",
          }}
        >
          {instructionName}
        </BootstrapDialogTitle>
        <DialogContent dividers className="AddStudyContainer">
          <div className="quill_component">
            <ReactQuill
              value={element.description}
              readOnly
              modules={{ toolbar: false }}
            />
          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button
           // dangerouslySetInnerHTML={{ __html: element.description }}
            autoFocus
            onClick={handleClose}
            className="view_instruction_closebtn"
          >
            Close
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
}
