import React from "react";
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "antd";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import OutlinedInput from "@mui/material/OutlinedInput";
import { editStudy, moderatorImages } from "../../studies";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { htmlToText } from "html-to-text";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EditCompTwo({
  open,
  onClose,
  data,
  editImages,
  getModerator,
}) {
  const [openOption, SetOpenOption] = useState(false);
  const [image, setImage] = useState([]);
  const [browseOpen, setBrowseOpen] = useState(false);
  const [selectedCount, setSelectedCount] = useState(editImages?.length);
  const brands = data?.brandNames;
  const reactQuillRef = useRef();
  const [searchInput, setSearchInput] = useState("");
  const [comparision, setComparision] = useState();

  const [fields, setFields] = useState([]);

  const [emptyFields, setEmptyFields] = useState([]);
  const [editImagesData, setEditImagesData] = useState();
  const [emptyFieldsExplination, setEmptyFieldsExplination] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [emptyFieldsExplinationSelect, setEmptyFieldsExplinationSelect] =
    useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setFields(data?.questions || []);
    setComparision(
      data?.comparision_question.length !== 0
        ? data?.comparision_question
        : [{ name: "", type: 2 }]
    );
    setEditImagesData(editImages);
  }, [data]);

  const browse = async () => {
    const response = await moderatorImages();
    if (response) {
      setImage(
        response.data.map((ele) => {
          return {
            url: ele.url,
            id: ele.id,
            name: ele.name,
          };
        })
      );
    }
  };

  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ color: [] }, { background: [] }],
  ];

  const handleInputChange = (index, event) => {
    const updatedEmptyFields = emptyFieldsExplination.filter(
      (field) => field !== index
    );
    const plainText = htmlToText(event);

    if (plainText.trim().length === 0) {
      updatedEmptyFields.push(index);
    }
    setEmptyFieldsExplination(updatedEmptyFields);

    const values = [...fields];
    const inputName = event;
    values[index].name = inputName;
    setFields(values);
  };
  const handleOptionChange = (event, index) => {
    const newFields = [...fields];

    const selectedOptions = event.target.value;
    if (selectedOptions.includes("Select All")) {
      const allOptions = brands.slice();

      const currentOptions = newFields[index].brands.map((ele) => ele.name);

      if (currentOptions.length === allOptions.length) {
        newFields[index].brands = [];
      } else {
        newFields[index].brands = allOptions.map((option) => ({
          name: option,
        }));
      }
    } else {
      const updatedBrands = selectedOptions.map((option) => {
        const existingBrand = newFields[index].brands.find(
          (brand) => brand.name === option
        );
        return existingBrand ? existingBrand : { name: option };
      });

      newFields[index] = {
        ...newFields[index],
        brands: updatedBrands,
      };
    }

    setFields(newFields);
    SetOpenOption(false);
    const updatedEmptyFields = emptyFieldsExplinationSelect.filter(
      (field) => field !== index
    );
    if (selectedOptions.length === 0) {
      updatedEmptyFields.push(index);
    }
    setEmptyFieldsExplinationSelect(updatedEmptyFields);
  };

  const handleRemoveField = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  };

  const handleAddField = () => {
    setFields([...fields, { name: "", brands: [], type: 1 }]);
  };

  const handleBrowserEditOpen = () => {
    setBrowseOpen(true);
    setSearchInput("");
    browse();
  };

  const handleBrowserEditClose = () => {
    setBrowseOpen(false);
    setEditImagesData(editImages);
    setSelectedCount(editImages?.length);
  };

  const imagesEditSubmit = () => {
    setBrowseOpen(false);
    setEditImagesData(editImagesData);
  };

  const selectAll_clear = (id) => {
    if (id === "all") {
      setEditImagesData([]);
      setSelectedCount(0);
      return;
    }
  };

  const deleteClick = (index) => {
    const newImagesUrlArray = [...editImagesData];
    newImagesUrlArray.splice(index, 1);
    setEditImagesData(newImagesUrlArray);
    setSelectedCount(selectedCount - 1);
  };

  const handleChangeEditCheck = (e, id) => {
    const MAX_LENGTH = 50;
    if (editImagesData.length >= MAX_LENGTH) {
      e.preventDefault();
      alert(`Cannot select images more than ${MAX_LENGTH}`);
      return;
    }
    let images_array = [...editImagesData];

    const checked = e.target.checked;

    const index = images_array.findIndex((img) => img.id === id);
    if (checked) {
      const MAX_LENGTH = 50;
      if (images_array.length < MAX_LENGTH) {
        images_array.push({ url: e.target.value, id: id, checked: true });
        setSelectedCount(selectedCount + 1);
      } else {
        alert(`Please select only ${MAX_LENGTH} images.`);
        e.target.checked = false;
      }
    } else {
      images_array.splice(index, 1);
      setSelectedCount(selectedCount - 1);
    }
    setEditImagesData(images_array);
  };

  const handleInputComparisionChange = (index, event) => {
    const updatedEmptyFields = emptyFields.filter((field) => field !== index);
    const plainText = htmlToText(event);
    if (plainText.trim().length === 0) {
      updatedEmptyFields.push(index);
    }
    setEmptyFields(updatedEmptyFields);
    const values = [...comparision];
    values[index].name = event;
    setComparision(values);
  };

  const handleOpen = (index) => {
    SetOpenOption(index);
  };

  const handleExited = () => {
    SetOpenOption(-1);
  };
  const handleAddComparisionField = () => {
    setComparision([...comparision, { name: "", type: "2" }]);
  };

  const handleRemoveComparisionField = (index) => {
    const newFields = [...comparision];
    newFields.splice(index, 1);
    setComparision(newFields);
  };
  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchValue !== "") {
      const filteredData = image.filter((item) => {
        return item.name.toLowerCase().includes(searchValue);
      });

      if (filteredData.length === 0) {
        setFilteredResults([]);
      } else {
        setFilteredResults(filteredData);
      }
    } else {
      setFilteredResults(image);
    }
  };
  const handleClickCancelEdit = () => {
    const originalFields = data?.questions || [];
    const originalComparision = data?.comparision_question || [];
    const originalEditImages = editImages;

    setFields(originalFields);
    setComparision(originalComparision);
    setEditImagesData(originalEditImages);

    onClose();
  };
  const formDataSubmit = async (e) => {
    e.preventDefault();
    const timezoneOffsetInMinutes = moment().utcOffset();
    const startDate = moment(data.startDate.$d)
      .add(timezoneOffsetInMinutes, "minutes")
      .toDate();
    const endDate = moment(data.endDate.$d)
      .add(timezoneOffsetInMinutes, "minutes")
      .toDate();
    const emptyFieldsExplination = fields.reduce((acc, field, index) => {
      const plainText = htmlToText(field.name);
      if (plainText.trim().length === 0) {
        return [...acc, index];
      }
      return acc;
    }, []);

    if (emptyFieldsExplination.length > 0) {
      setEmptyFieldsExplination(emptyFieldsExplination);
      return;
    }

    const emptyFieldsExplinationSelect = fields.reduce((acc, field, index) => {
      if (field.brands.length === 0) {
        return [...acc, index];
      }
      return acc;
    }, []);

    if (emptyFieldsExplinationSelect.length > 0) {
      setEmptyFieldsExplinationSelect(emptyFieldsExplinationSelect);
      return;
    }
    if (brands.length > 1) {
      const emptyFields = comparision.reduce((acc, field, index) => {
        const plainText = htmlToText(field.name);
        if (plainText.trim().length === 0) {
          return [...acc, index];
        }
        return acc;
      }, []);
      if (emptyFields.length > 0) {
        setEmptyFields(emptyFields);
        return;
      }
    }

    if (loading === false) {
      setLoading(true);
      const expFields = [];
      fields?.map((ele) => {
        expFields.push({
          name: ele?.name,
          brandName: ele?.brands.map((ele) => ele?.name),
          type: ele?.type,
        });
      });

      try {
        const response = await editStudy({
          studyId: data.studyId,
          title: data.title,
          timeout: data.timeout,
          startDate: startDate,
          endDate: endDate,
          moderatorName: data.moderatorName,
          moderatorId: data.moderatorId,
          explanationQuestions: expFields,
          comparisonQuestions: comparision,
          images: editImagesData,
          brandNames: data.brandNames,
        });
        if (response.success === true) {
          setLoading(false);
          getModerator();
        }
      } catch {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div>
        <BootstrapDialog
          onClose={onClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          // onBackdropClick="false"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "820px",
                height: "70%",

                zIndex: 100,
              },
            },
          }}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={onClose}
            sx={{
              background: "#093975",
              color: "white",
              fontFamily: "RaleWay",
              fontWeight: "600",
            }}
          >
            Edit Study
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <div>
              <React.Fragment>
                <div>
                  <p className="studynameTwo">Add Explanation Question</p>
                  {fields?.map((field, index) => (
                    <div
                      key={index}
                      className="d-flex overall_explination_block"
                    >
                      <div style={{ display: "block" }}>
                        <ReactQuill
                          ref={reactQuillRef}
                          value={field?.name}
                          onChange={(event) => handleInputChange(index, event)}
                          modules={{ toolbar: toolbarOptions }}
                          className="react_quill_edit_explination"
                        />
                        {emptyFieldsExplination.includes(index) && (
                          <div className="error_message_form">
                            This field is required
                          </div>
                        )}
                      </div>
                      <FormControl sx={{ m: 1 }}>
                        <Select
                          id="demo-multiple-checkbox"
                          multiple
                          value={field?.brands?.map((ele) => ele?.name) || []}
                          onChange={(event) => handleOptionChange(event, index)}
                          className="selectIn_EditStudyTwo"
                          input={<OutlinedInput label="Tag" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            PaperProps: {
                              style: {
                                width: 250,
                                overflowY: "scroll",
                                height: 250,
                              },
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            onExited: handleExited,
                          }}
                          onOpen={() => handleOpen(index)}
                          onClose={() => SetOpenOption(-1)}
                          open={openOption === index}
                        >
                          {brands?.length > 1 ? (
                            <MenuItem key="Select All" value="Select All">
                              <Checkbox
                                checked={field.brands.length === brands?.length}
                              />

                              <ListItemText primary="Select All" />
                            </MenuItem>
                          ) : null}

                          {brands?.map((brand) => (
                            <MenuItem key={brand} value={brand}>
                              <Checkbox
                                checked={
                                  field?.brands
                                    ?.map((ele) => ele.name)
                                    .indexOf(brand) > -1
                                }
                              />
                              <ListItemText primary={brand} />
                            </MenuItem>
                          ))}
                        </Select>
                        {emptyFieldsExplinationSelect.includes(index) && (
                          <span className="error_message_form">
                            This field is required
                          </span>
                        )}
                      </FormControl>
                      {fields?.length > 1 && (
                        <div style={{ marginLeft: "20px" }}>
                          <Button
                            className="cancel_btn_in_editTwo "
                            onClick={() => handleRemoveField(index)}
                          >
                            <span className="x_editbtn"> x</span>
                          </Button>
                        </div>
                      )}
                    </div>
                  ))}
                  <button
                    onClick={handleAddField}
                    className="edit_question_two_btn"
                  >
                    <span className="edit_question_Cancel_two">+</span>{" "}
                    <span className="edit_question_two">Add Question</span>
                  </button>
                </div>
                {brands?.length <= 1 ? null : (
                  <div>
                    <p className="studynameTwo">Add Comparison Question</p>
                    <div>
                      {comparision?.map((field, index) => (
                        <div
                          key={index}
                          className="d-flex overalledit_comparision_block "
                        >
                          <div style={{ display: "block" }}>
                            <ReactQuill
                              ref={reactQuillRef}
                              value={field.name}
                              onChange={(event) =>
                                handleInputComparisionChange(index, event)
                              }
                              modules={{ toolbar: toolbarOptions }}
                              className="react_quill_comparision"
                            />
                            {emptyFields.includes(index) && (
                              <span className="eidt_error_message_form">
                                This field is required
                              </span>
                            )}
                          </div>
                          {comparision?.length > 1 && (
                            <button
                              className="cancel_btn_in_editTwo"
                              onClick={() =>
                                handleRemoveComparisionField(index)
                              }
                            >
                              <span className="x_btn"> x</span>
                            </button>
                          )}
                        </div>
                      ))}
                      <button
                        onClick={handleAddComparisionField}
                        className="edit_question_two_btn"
                      >
                        <span className="edit_question_Cancel_two">+</span>{" "}
                        <span className="edit_question_two">Add Question</span>
                      </button>
                    </div>
                  </div>
                )}
                {/*----------------------------------- Browser Images ----------------------------------------*/}
                <div>
                  <p className="studynameTwo">
                    Images <span style={{ color: "#ff371b" }}>*</span>
                  </p>

                  <div>
                    <div>
                      <div>
                        <Button
                          variant="outlined"
                          onClick={handleBrowserEditOpen}
                          className="browserbtnEdit"
                        >
                          Browser
                        </Button>
                      </div>
                    </div>
                    <BootstrapDialog
                      onClose={handleBrowserEditClose}
                      aria-labelledby="customized-dialog-title"
                      open={browseOpen}
                      sx={{
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            width: "100%",
                            maxHeight: "98%",
                            height: "96%",
                            maxWidth: "98%",
                            zIndex: 100,
                          },
                        },
                      }}
                    >
                      <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleBrowserEditClose}
                        sx={{
                          background: "#093975",
                          color: "white",
                          fontFamily: "RaleWay",
                          fontWeight: "600",
                        }}
                      >
                        <div className="d-flex ">
                          <div className="pl-2 flex_grow">
                            <div className="d-flex">
                              <div>Select Edit Images</div>
                              <div className="d-flex">
                                {selectedCount > 0 && (
                                  <div className="pl-2">
                                    <button
                                      onClick={() =>
                                        selectAll_clear("all", {
                                          target: { checked: false },
                                        })
                                      }
                                      className="deselect_btn_edit"
                                    >
                                      <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />
                                    </button>
                                  </div>
                                )}
                                <div className="pl-2">
                                  <p className="selected_count_edit ">
                                    {selectedCount} selected
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <input
                              icon="search"
                              placeholder="Search"
                              className="searchinput_addStudy"
                              onChange={(e) => searchItems(e.target.value)}
                            />
                          </div>
                        </div>
                      </BootstrapDialogTitle>
                      <DialogContent dividers className="AddEditContainer">
                        <div>
                          {searchInput.length >= 1 ? (
                            <React.Fragment>
                              <div>
                                <div className=" browse_edit">
                                  <div className=" browser_edit">
                                    {filteredResults.length === 0 ? (
                                      <p className="no_filtered_data">
                                        No Images Found
                                      </p>
                                    ) : (
                                      filteredResults.map((imag) => {
                                        return (
                                          <div>
                                            <p>{imag.name}</p>
                                            <label>
                                              <input
                                                type="checkbox"
                                                className="checkbox"
                                                checked={editImagesData
                                                  .map((e) => e?.id)
                                                  .includes(imag?.id)}
                                                onChange={(e) =>
                                                  handleChangeEditCheck(
                                                    e,
                                                    imag.id
                                                  )
                                                }
                                                value={imag.url}
                                              ></input>
                                              <div className="edit_backgroundLoading_images">
                                                <img
                                                  src={imag.url}
                                                  width="100%"
                                                  alt="img not found"
                                                  className="borderForImagesStudy"
                                                />
                                              </div>
                                            </label>
                                          </div>
                                        );
                                      })
                                    )}
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div>
                                <div className=" browse_edit">
                                  <div className=" browser_edit">
                                    {image.map((imag) => {
                                      return (
                                        <div key={imag.id}>
                                          <p>{imag?.name}</p>
                                          <label>
                                            <input
                                              type="checkbox"
                                              className="checkbox"
                                              value={imag.url}
                                              checked={editImagesData
                                                .map((e) => e?.id)
                                                .includes(imag?.id)}
                                              onChange={(e) =>
                                                handleChangeEditCheck(
                                                  e,
                                                  imag.id
                                                )
                                              }
                                            ></input>
                                            <div className="edit_backgroundLoading_images">
                                              <img
                                                src={imag.url}
                                                width="100%"
                                                alt="img not found"
                                                className="borderForImagesEdit"
                                              />
                                            </div>
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <button
                          autoFocus
                          onClick={handleBrowserEditClose}
                          className="editStudyCancelBtn"
                        >
                          Cancel
                        </button>
                        <button
                          className="editStudySaveBtn"
                          onClick={imagesEditSubmit}
                        >
                          Next
                        </button>
                      </DialogActions>
                    </BootstrapDialog>
                  </div>
                </div>

                <div className="browsedEditImages">
                  {editImagesData?.map((link, index) => {
                    return (
                      <div className="image_editIcon" key={index}>
                        <div className="Browsed_images_loading_two">
                          <img
                            src={link?.url}
                            alt="no image found"
                            width="100px"
                            height="100px"
                            className="browsed_imaged_imagesedit"
                          />
                        </div>

                        <FontAwesomeIcon
                          icon="fa-solid fa-circle-xmark"
                          onClick={() => deleteClick(index)}
                          className="wrongIcon_for_edit"
                        />
                      </div>
                    );
                  })}
                </div>
              </React.Fragment>
            </div>
          </DialogContent>
          {/*----------------------------------- Browser Images End-----------------------*/}
          <DialogActions>
            <button
              autoFocus
              onClick={handleClickCancelEdit}
              className="editStudyCancelBtn"
            >
              Cancel
            </button>
            <button
              autoFocus
              onClick={formDataSubmit}
              className={
                loading ? "editStudySaveBtn_loading" : "editStudySaveBtn"
              }
            >
              Save
            </button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </div>
  );
}
