import React, { useEffect, useState } from "react";
import {
  getStudyQuestions,
  ExplanationQuestionResponse,
} from "../../participants";
import netLoader from "../../../images/loading-slow-net.gif";
import "../ExplinationQuestions/explinationquestions.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
export default function ExplinationQuestion() {
  const user_id_data = sessionStorage.getItem("loginUserId");
  const [images, setImages] = useState([]);
  const [textareas, setTextareas] = useState("");
  const [explanationQuestion, setExplanationQuestion] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { propsData } = location.state || {};
  const [brandId, setBrandId] = useState("");
  const [brand, setBrand] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [studyid, setStudyId] = useState("");
  const [dummyData, setDummyData] = useState();
  const [dummyDataTwo, setDummyDataTwo] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [emptyFields, setEmptyFields] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    if (propsData && propsData.length > 0) {
      setBrandId(propsData[0].id);
      setStudyId(id);
      setBrand(propsData[0].name);
      setIsLoading(false);
    }
  }, [propsData]);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      if (brandId && studyid) {
        setIsLoading(true);
        const response = await getStudyQuestions({
          brandId,
          studyid,
          user_id_data,
        });
        if (response) {
          if (response.success == true)
            if (response.data.images.length === 0) {
              if (currentIndex < propsData.length - 1) {
                setCurrentIndex(currentIndex + 1);
                const nextBrand = propsData[currentIndex + 1];
                setBrand(nextBrand.name);
                setBrandId(propsData[currentIndex + 1].id);
                setStudyId(id);
                setIsLoading(false);
              } else {
                setTextareas({});
                navigate(`/comparision/${id}`, {
                  state: { propsData: propsData },
                  replace: true,
                });
                return;
              }
            } else {
              setExplanationQuestion(response.data.questions);
              setImages(response.data.images);
              setIsLoading(false);
            }
        }
      }
    };
    fetchData();
  }, [brandId, studyid]);

  const handleChange = (event, id) => {
    setTextareas((prevState) => ({
      ...prevState,
      [id]: event.target.value,
    }));
    const updatedEmptyFields = emptyFields.filter((field) => field !== id);
    if (event.target.value.trim().length === 0) {
      updatedEmptyFields.push(id);
    }
    setEmptyFields(updatedEmptyFields);
    setDummyData(
      {
        text_response: Object.keys(textareas).map((key) => {
          return {
            question_id: Number(key),
            response_text: textareas[key],
          };
        }),
      }.text_response
    );
    setDummyDataTwo({
      user_id: parseInt(user_id_data),
      study_id: studyid,
      responses: dummyData,
    });
  };

  const explanationHandleClick = async () => {
    const emptyFields = explanationQuestion
      .filter((ele) => {
        return !(textareas[ele.id] && textareas[ele.id].trim().length > 0);
      })
      .map((ele) => ele.id);

    if (emptyFields.length > 0) {
      setEmptyFields(emptyFields);
      return;
    }

    const responses = {
      user_id: parseInt(user_id_data),
      study_id: studyid,
      brandId: brandId,
      responses: Object.keys(textareas).map((key) => {
        return {
          question_id: Number(key),
          response_text: textareas[key],
        };
      }),
    };

    if (currentIndex < propsData.length - 1) {
      if (loading === false) {
        setLoading(true);
        const response = await ExplanationQuestionResponse(responses);
        if (response) {
          setLoading(false);
          if (response.success === true) {
            setCurrentIndex(currentIndex + 1);
            const nextBrand = propsData[currentIndex + 1];
            setBrand(nextBrand.name);
            setBrandId(propsData[currentIndex + 1].id);
            setStudyId(id);
            setTextareas({});
            setEmptyFields([]);
          }
        }
      }
    } else {
      setBrandId(propsData[currentIndex].id);
      setStudyId(id);
      if (loading === false) {
        setLoading(true);
        const response = await ExplanationQuestionResponse(responses);
        if (response) {
          setLoading(false);
          if (response.success === true) {
            setTextareas({});
            navigate(`/comparision/${id}`, {
              state: { propsData: propsData },
              replace: true,
            });
            return;
          }
        }
      }
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loading-container">
          <img className="img_loader" src={netLoader} />
        </div>
      ) : (
        <div>
          <div>
            <p className="please_select_the">
              Below are the images you selected as matching YOUR IMAGE of{" "}
              <span className="brands_For_Selection">{brand}</span>.
            </p>
            <div>
              <div className="selectOverSelectionExplanation">
                <div className="selectOverSelectionExplanaitonOne">
                  {images.map((imag) => {
                    return (
                      <div className="adjust_images_height">
                        <img
                          src={imag.url}
                          width="100%"
                          alt="img not found"
                          className="borderForImages"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div>
              {explanationQuestion?.map((ele, index) => (
                <div key={index}>
                  <div className="question_numbering">
                    <span style={{ color: "red" }}>*</span>
                    <span>{index + 1}.</span>

                    <span
                      style={{
                        width: "99%",
                        maxWidth: "99%",
                        wordWrap: "break-word",
                      }}
                      className="explination_question_data "
                      dangerouslySetInnerHTML={{ __html: ele.name }}
                    ></span>
                  </div>

                  <textarea
                    className="data"
                    value={textareas[ele?.id] || ""}
                    onChange={(event) => {
                      handleChange(event, ele?.id);
                    }}
                  />
                  <br></br>
                  {emptyFields.includes(ele.id) && (
                    <span className="error_message_explination">
                      * Above question is mandatory to answer
                    </span>
                  )}
                </div>
              ))}
            </div>
            <button
              onClick={explanationHandleClick}
              className={
                loading
                  ? "explanation_Next_Btn_loading"
                  : "explanation_Next_Btn "
              }
            >
              Next
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
