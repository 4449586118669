import React from "react";
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "../AddStudyDataBtn/addstudybtn.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { addStudy, moderatorImages } from "../../studies.js";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "antd";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { htmlToText } from "html-to-text";

library.add(fas);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddStudyCompTwo({
  open: popupOpen,
  onClose,
  data,
  getModerator,
}) {
  const [browseOpen, setBrowseOpen] = useState(false);
  const [image, setImage] = useState([]);

  const [content, setContent] = useState("");
  const [check, setCheck] = useState([]);
  const [counter, setCounter] = useState(0);
  const [imgsSrc, setImgsSrc] = useState([]);
  let [fields, setFields] = useState([{ name: "", brandName: [], type: 1 }]);

  const [selectedCount, setSelectedCount] = useState(0);
  const brands = data?.brandNames?.map((ele) => ele);
  let [comparision, setComparision] = useState([{ name: "", type: 2 }]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const reactQuillRef = useRef();
  const [filteredResults, setFilteredResults] = useState([]);

  const [open, setOpen] = useState(false);
  const [emptyFields, setEmptyFields] = useState([]);

  const [emptyFieldsExplination, setEmptyFieldsExplination] = useState([]);

  const [emptyFieldsExplinationSelect, setEmptyFieldsExplinationSelect] =
    useState([]);

  const handleChangeCheck = (id, e) => {
    if (id === "all") {
      setCheck([]);
      setSelectedCount(0);
      return;
    }

    let images_array = [...check];

    const checked = e.target.checked;
    const index = images_array.findIndex((img) => img.id === id);
    if (checked) {
      const MAX_LENGTH = 50;
      if (images_array.length < MAX_LENGTH) {
        images_array.push({ url: e.target.value, id: id, checked: true });
        setSelectedCount(selectedCount + 1);
      } else {
        alert(`Please select only ${MAX_LENGTH} images.`);
        e.target.checked = false;
      }
    } else {
      images_array.splice(index, 1);
      setSelectedCount(selectedCount - 1);
    }
    setCheck(images_array);
  };

  const browse = async () => {
    setLoading(true);
    const response = await moderatorImages();
    if (response) {
      setLoading(false);
      setImage(
        response.data?.map((ele) => {
          return {
            url: ele.url,
            id: ele.id,
            name: ele.name,
          };
        })
      );
    }
  };

  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ color: [] }, { background: [] }],
  ];

  useEffect(() => {
    browse();
  }, []);
  // -----------------------------------------------------------------------------

  const handleAddField = () => {
    setFields([...fields, { name: "", brandName: [], type: 1 }]);
  };

  const handleRemoveField = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  };
  const handleInputComparisionChange = (index, event, value) => {
    const plainText = htmlToText(event);

    const updatedEmptyFields = emptyFields.filter((field) => field !== index);

    if (plainText.trim().length === 0) {
      updatedEmptyFields.push(index);
    }
    setEmptyFields(updatedEmptyFields);
    const values = [...comparision];
    const inputName = event;
    values[index].name = inputName;
    setComparision(values);
  };
  const handleInputChange = (index, event, value) => {
    const updatedEmptyFields = emptyFieldsExplination.filter(
      (field) => field !== index
    );
    const plainText = htmlToText(event);

    if (plainText.trim().length === 0) {
      updatedEmptyFields.push(index);
    }
    setEmptyFieldsExplination(updatedEmptyFields);

    const values = [...fields];
    const inputName = event;
    values[index].name = inputName;
    setFields(values);
  };
  const handleOptionChange = (event, index) => {
    const newFields = [...fields];
    const selectedOptions = event.target.value;
    if (selectedOptions.includes("Select All")) {
      const allOptions = brands.slice();

      const currentOptions = newFields[index].brandName;

      if (currentOptions.length === allOptions.length) {
        newFields[index].brandName = [];
      } else {
        newFields[index].brandName = allOptions;
      }
    } else {
      newFields[index].brandName = selectedOptions;
    }
    setFields(newFields);
    setOpen(false);
    const updatedEmptyFields = emptyFieldsExplinationSelect.filter(
      (field) => field !== index
    );
    if (selectedOptions.length === 0) {
      updatedEmptyFields.push(index);
    }
    setEmptyFieldsExplinationSelect(updatedEmptyFields);
  };

  // -----------------------------------------------------------------------------
  const handleBrowserOpen = () => {
    setBrowseOpen(true);
    setSearchInput("");
    setCheck(imgsSrc);
  };
  const handleBrowserClose = () => {
    setSelectedCount(imgsSrc?.length);
    setBrowseOpen(false);
  };

  const imagesSubmit = () => {
    setBrowseOpen(false);
    setImgsSrc(check);
  };

  const handleOpen = (index) => {
    setOpen(index);
  };
  const handleChange = (value) => {
    setContent(value);
  };
  const handleExited = () => {
    setOpen(-1);
  };
  const deleteClick = (index) => {
    const newImagesUrlArray = [...imgsSrc];
    newImagesUrlArray.splice(index, 1);
    setImgsSrc(newImagesUrlArray);
    setSelectedCount(selectedCount - 1);
  };

  const handleAddComparisionField = () => {
    setComparision([...comparision, { name: "", type: 2 }]);
  };
  const handleRemoveComparisionField = (index) => {
    const newFields = [...comparision];
    newFields.splice(index, 1);
    setComparision(newFields);
  };
  // --------------------------------------------------------------------------------------------
  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchValue !== "") {
      const filteredData = image.filter((item) => {
        return item.name.toLowerCase().includes(searchValue);
        //  return item.name.toLowerCase().includes(searchValue.toLowerCase());
      });

      if (filteredData.length === 0) {
        setFilteredResults([]);
      } else {
        setFilteredResults(filteredData);
      }
    } else {
      setFilteredResults(image);
    }
  };

  // -----------------------------------------------FORM SUBMIT --------------------------------
  const handleCancel = () => {
    setImgsSrc([]);

    fields = [fields[0]];
    fields[0].name = "";
    fields[0].brandName = [];
    fields[0].type = 1;
    comparision = [comparision[0]];
    comparision[0].name = "";
    comparision[0].type = 2;
    onClose();
  };
  const formDataSubmit = async (e) => {
    e.preventDefault();
    const emptyFieldsExplination = fields.reduce((acc, field, index) => {
      const plainText = htmlToText(field.name);
      if (plainText.trim().length === 0) {
        return [...acc, index];
      }
      return acc;
    }, []);

    if (emptyFieldsExplination.length > 0) {
      setEmptyFieldsExplination(emptyFieldsExplination);
      return;
    }
    const emptyFieldsExplinationSelect = fields.reduce((acc, field, index) => {
      if (field.brandName.length === 0) {
        return [...acc, index];
      }
      return acc;
    }, []);

    if (emptyFieldsExplinationSelect.length > 0) {
      setEmptyFieldsExplinationSelect(emptyFieldsExplinationSelect);
      return;
    }
    if (brands?.length > 1) {
      const emptyFields = comparision.reduce((acc, field, index) => {
        const plainText = htmlToText(field.name);
        if (plainText.trim().length === 0) {
          return [...acc, index];
        }
        return acc;
      }, []);

      if (emptyFields.length > 0) {
        setEmptyFields(emptyFields);
        return;
      }
    }
    if (loading === false) {
      try {
        setLoading(true);
        const response = await addStudy({
          ...data,
          images: imgsSrc?.map((ele) => ({ url: ele.url, id: ele.id })),
          explanationQuestions: fields,
          comparisonQuestions: brands?.length <= 1 ? [] : comparision,
        });
        if (response.success) {
          getModerator();
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={popupOpen}
        // onBackdropClick="false"

        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "820px",
              height: "70%",
              zIndex: 100,
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCancel}
          sx={{
            background: "#093975",
            color: "white",
            fontFamily: "RaleWay",
            fontWeight: "600",
          }}
        >
          Add Study
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div>
            <React.Fragment>
              <div>
                <form>
                  <div>
                    <p className="studynameTwo">Add Explanation Question</p>
                    {fields?.map((field, index) => (
                      <div
                        key={index}
                        className="d-flex overall_addexplination_block"
                      >
                        <div style={{ display: "block" }}>
                          <ReactQuill
                            ref={reactQuillRef}
                            value={field.name}
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                            modules={{ toolbar: toolbarOptions }}
                            className="reactquill_explination"
                          />

                          {emptyFieldsExplination.includes(index) && (
                            <div className="error_message_form">
                              This field is required
                            </div>
                          )}
                        </div>

                        <FormControl sx={{ m: 1 }}>
                          <Select
                            id={`demo-multiple-checkbox-${index}`}
                            multiple
                            value={field.brandName}
                            onChange={(event) =>
                              handleOptionChange(event, index)
                            }
                            className="selectIn_StudyTwo"
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              PaperProps: {
                                style: {
                                  width: 250,
                                },
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              onExited: handleExited,
                            }}
                            onOpen={() => handleOpen(index)}
                            onClose={() => setOpen(-1)}
                            open={open === index}
                          >
                            {brands?.length > 1 ? (
                              <MenuItem key="Select All" value="Select All">
                                <Checkbox
                                  checked={
                                    field.brandName?.length === brands?.length
                                  }
                                />

                                <ListItemText primary="Select All" />
                              </MenuItem>
                            ) : null}

                            {brands?.map((name) => (
                              <MenuItem key={name} value={name}>
                                <Checkbox
                                  checked={field.brandName.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>

                          {emptyFieldsExplinationSelect.includes(index) && (
                            <span className="error_message_form">
                              This field is required
                            </span>
                          )}
                        </FormControl>
                        {fields.length > 1 && (
                          <Button
                            className="cancel_btn_in_addTwo"
                            onClick={() => handleRemoveField(index)}
                          >
                            <span className="x_btn"> x</span>
                          </Button>
                        )}
                      </div>
                    ))}
                    <Button
                      onClick={handleAddField}
                      className="Add_question_two_btn"
                    >
                      <span className="Add_question_Cancel_two">+</span>{" "}
                      <span className="Add_question_two">Add Question</span>
                    </Button>
                  </div>
                  {brands?.length <= 1 ? null : (
                    <div>
                      <p className="studynameTwo">Add Comparison Question</p>
                      {comparision?.map((field, index) => (
                        <div
                          key={index}
                          className="d-flex overall_comparision_block"
                        >
                          <div style={{ display: "block" }}>
                            <ReactQuill
                              ref={reactQuillRef}
                              value={field?.name}
                              onChange={(event) =>
                                handleInputComparisionChange(index, event)
                              }
                              modules={{ toolbar: toolbarOptions }}
                              className="react_quill_comparision"
                            />

                            {emptyFields.includes(index) && (
                              <span className="error_message_form">
                                This field is required
                              </span>
                            )}
                          </div>
                          {comparision?.length > 1 && (
                            <Button
                              className="cancel_btn_in_addTwo"
                              onClick={() =>
                                handleRemoveComparisionField(index)
                              }
                            >
                              <span className="x_btn"> x</span>
                            </Button>
                          )}
                        </div>
                      ))}
                      <Button
                        onClick={handleAddComparisionField}
                        className="Add_question_two_btn"
                      >
                        <span className="Add_question_Cancel_two">+</span>{" "}
                        <span className="Add_question_two">Add Question</span>
                      </Button>
                    </div>
                  )}

                  <div>
                    <p className="studynameTwo">
                      Images <span style={{ color: "#ff371b" }}>*</span>
                    </p>
                    <div>
                      <div>
                        <div>
                          <Button
                            variant="outlined"
                            onClick={handleBrowserOpen}
                            className="browserbtn"
                          >
                            Browser
                          </Button>
                        </div>
                      </div>
                      <BootstrapDialog
                        onClose={handleBrowserClose}
                        aria-labelledby="customized-dialog-title"
                        open={browseOpen}
                        // onBackdropClick="false"
                        sx={{
                          "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                              width: "100%",
                              maxWidth: "98%",
                              height: "96%",
                              zIndex: 100,
                            },
                          },
                        }}
                      >
                        <BootstrapDialogTitle
                          id="customized-dialog-title"
                          onClose={handleBrowserClose}
                          sx={{
                            background: "#093975",
                            color: "white",
                            fontFamily: "RaleWay",
                            fontWeight: "600",
                          }}
                        >
                          <div className="d-flex overall_browser">
                            <div className="pl-2 flex_grow">
                              <div className="d-flex">
                                <div>Select Study Images</div>
                                <div>
                                  <div className="d-flex">
                                    {selectedCount > 0 && (
                                      <div className="pl-2">
                                        <button
                                          onClick={() =>
                                            handleChangeCheck("all", {
                                              target: { checked: false },
                                            })
                                          }
                                          className="deselect_btn"
                                        >
                                          <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />
                                        </button>
                                      </div>
                                    )}
                                    <div>
                                      <p className="selected_count ">
                                        {selectedCount} selected
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <input
                                icon="search"
                                placeholder="Search"
                                className="searchinput_addStudy"
                                onChange={(e) => searchItems(e.target.value)}
                              />
                            </div>
                          </div>
                        </BootstrapDialogTitle>
                        <DialogContent dividers className="AddStudyContainer">
                          <div>
                            {searchInput.length >= 1 ? (
                              <React.Fragment>
                                <div>
                                  <div className=" browse">
                                    <div className=" browser">
                                      {filteredResults?.length === 0 ? (
                                        <p className="no_filtered_data">
                                          No Images Found
                                        </p>
                                      ) : (
                                        filteredResults?.map((imag) => {
                                          return (
                                            <div>
                                              <p>{imag.name}</p>
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  checked={check
                                                    .map((e) => e.id)
                                                    .includes(imag.id)}
                                                  onChange={(e) =>
                                                    handleChangeCheck(
                                                      imag.id,
                                                      e
                                                    )
                                                  }
                                                  value={imag.url}
                                                ></input>
                                                <div className="backgroundLoading_images">
                                                  <img
                                                    src={imag.url}
                                                    width="100%"
                                                    alt="img not found"
                                                    className="borderForImagesStudy"
                                                  />
                                                </div>
                                              </label>
                                            </div>
                                          );
                                        })
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <div>
                                  {image?.length == 0 ? (
                                    <div className="center">
                                      <p className="no_browse_data ">No Data</p>
                                    </div>
                                  ) : (
                                    <div className="browser">
                                      {image?.map((imag) => {
                                        return (
                                          <div key={imag.id}>
                                            <p>{imag.name}</p>
                                            <div className="backgroundLoading_images">
                                              <label>
                                                <input
                                                  type="checkbox"
                                                  className="checkbox"
                                                  checked={check
                                                    .map((e) => e.id)
                                                    .includes(imag.id)}
                                                  onChange={(e) =>
                                                    handleChangeCheck(
                                                      imag.id,
                                                      e
                                                    )
                                                  }
                                                  value={imag.url}
                                                ></input>

                                                <div>
                                                  <div>
                                                    <img
                                                      src={imag.url}
                                                      alt="image not found"
                                                      className="borderForImagesStudy"
                                                    />
                                                  </div>
                                                </div>
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                              </React.Fragment>
                            )}
                          </div>
                        </DialogContent>
                        <DialogActions>
                          <button
                            autoFocus
                            onClick={handleBrowserClose}
                            className="addStudyCancelBtn"
                          >
                            Cancel
                          </button>
                          <button
                            className="addStudySaveBtn"
                            onClick={imagesSubmit}
                          >
                            Next
                          </button>
                        </DialogActions>
                      </BootstrapDialog>
                    </div>
                  </div>
                </form>
              </div>
            </React.Fragment>
            <div className="browsed_images">
              {imgsSrc?.map((link, index) => {
                return (
                  <div className="imageIcon" key={index}>
                    <img
                      src={link.url}
                      alt="image not found"
                      width="100%"
                      className="browsed_imaged_images"
                    />

                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-xmark"
                      onClick={() => deleteClick(index)}
                      className="wrongIcon"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            autoFocus
            onClick={handleCancel}
            className="addStudyCancelBtn"
          >
            Cancel
          </button>
          <button
            autoFocus
            onClick={formDataSubmit}
            className={loading ? "addStudySaveBtn_loading" : "addStudySaveBtn"}
          >
            Save
          </button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
