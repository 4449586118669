import React, { useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import { getStudyReport } from "../../participants";
import { LoadingOutlined } from "@ant-design/icons";

const ExcelJs = require("exceljs");

export default function StudyReportBtn(props) {
  const { ele } = props;

  const [loading, setLoading] = useState(false);

  const sheetTwo = async (finalData, workbook) => {
    setLoading(true);
    const sheet2 = workbook.addWorksheet("Images Count Report");
    // let defaultEachRowHeight = 80;
    // sheet2.properties.defaultRowHeight = defaultEachRowHeight;
    // sheet2.eachRow({ includeEmpty: false }, (row, rowNumber) => {
    //   row.height = defaultEachRowHeight;
    // });
    sheet2.views = [
      { state: "frozen", xSplit: 0, ySplit: 1 }, // Freeze from the third row downwards
    ];

    const toDataUrl = (image) => {
      const url = `${process.env.REACT_APP_API_URL}/auth/image?url=${image}`;
      const promise = new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
          var reader = new FileReader();
          reader.readAsDataURL(xhr.response);
          reader.onloadend = function () {
            resolve({ base64Url: reader.result });
          };
        };

        xhr.open("GET", url);
        xhr.setRequestHeader("client-id", `${process.env.REACT_APP_CLIENT_ID}`);
        xhr.setRequestHeader(
          "client-secret",
          `${process.env.REACT_APP_CLIENT_SECRET}`
        );
        xhr.responseType = "blob";
        xhr.send();
      });

      return promise;
    };
    let rowIndex = 1;
    let rowBrandHeight = 40;
    for (const brandData of finalData?.brandImageCount) {
      const brandName = brandData[0].brandName;
      const brandRowStart = sheet2.rowCount + 2;

      sheet2.mergeCells(brandRowStart, 1, brandRowStart, 3);
      sheet2.getCell(brandRowStart, 1).value = ` ${brandName}`;
      const brandSheetTwoCell = sheet2.getCell(brandRowStart, 1);

      brandSheetTwoCell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      brandSheetTwoCell.font = {
        bold: true,
      };
      brandSheetTwoCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "09A5ED" }, // Specify the background color (#FF94C8E4)126224
      };
      sheet2.getRow(brandRowStart).height = rowBrandHeight;
      // Define the columns for the brand
      sheet2.columns = [
        { header: "Title", key: "title", width: 15 },
        { header: "Photo", key: "thumbnail", width: 30 },
        { header: "Count", key: "count", width: 10 },
      ];
      sheet2.getRow(1).font = {
        bold: true,
      };
      sheet2.getRow(1).alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      sheet2.getRow(1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "DB880C" }, // Specify the background color (#FF94C8E4)126224
      };
      sheet2.getRow(1).border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      };
      const rowHeight = 80;
      for (const data of brandData) {
        const result = await toDataUrl(data.url);

        const row = sheet2.addRow({
          title: data.imageName,
          count: data.count,
        });
        row.height = rowHeight;
        row.eachCell((cell) => {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          };
        });
        rowIndex++;
        const imageId2 = workbook.addImage({
          base64: result.base64Url,
          extension: "jpeg",
        });

        // sheet2.addImage(imageId2, {
        //   tl: { col: 2, row: 2 },
        //   ext: { width: 100, height: 10 },
        // });
        const imageCell = row.getCell(sheet2.getColumn("thumbnail").number);
        imageCell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };

        row.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        sheet2.addImage(imageId2, {
          tl: {
            col: sheet2.getColumn("thumbnail").number - 1,
            row: rowIndex,
          },
          ext: { width: 80, height: 80 },
        });
      }
      rowIndex++;
      rowIndex++;
    }
    setLoading(false);
  };

  const exportToExcel = async (finalData) => {
    setLoading(true);
    if (finalData?.data != 0) {
      const workbook = new ExcelJs.Workbook();
      const sheet = workbook.addWorksheet("Study Report");

      let studyHeadingName;

      const existingColumns = [
        {
          header: "Resp No.",
          key: "respnumber",
        },
        {
          header: "First Name",
          key: "firstName",
        },
        {
          header: "Last Name",
          key: "lastName",
        },
      ];
      const headerRow = sheet.getRow(1);
      headerRow.font = { bold: true };
      headerRow.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      headerRow.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "DB880C" }, // Specify the background color (#FF94C8E4)126224
      };
      headerRow.border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      };

      sheet.views = [
        { state: "frozen", xSplit: 3, ySplit: 2 }, // Freeze from the third row downwards
      ];

      // ---------------All images responses Match\Doesnot Match-------------------
      // Function to sort the imagesSelections array by imageId in ascending order
      const sortImagesByImageId = (images) => {
        return images.sort((a, b) => {
          const imageIdA = a.imageName.replace(".jpg", "");
          const imageIdB = b.imageName.replace(".jpg", "");
          if (imageIdA < imageIdB) return -1;
          if (imageIdA > imageIdB) return 1;
          return 0;
        });
      };

      finalData?.data?.forEach((object, index) => {
        studyHeadingName = finalData?.studyName;
        if (index == 0) {
          let brand;
          const ovreallBrands = [];
          object?.imagesSelections?.forEach((brandName) => {
            brandName?.forEach((image) => {
              const brandName = image.brandName;
              if (!ovreallBrands.includes(brandName)) {
                ovreallBrands.push(brandName);
              }
            });
            const images = sortImagesByImageId(brandName);
            const startColumn = existingColumns?.length + 1;

            images?.forEach((image, index) => {
              const newImageId = image.imageName.replace(".jpg", "");
              const columnKey = `${image.brandName}_${newImageId}_${index + 1}`;
              brand = `${image.brandName}`;

              existingColumns.push({
                header: `${newImageId} - ${index + 1}`,
                key: columnKey,
              });
              sheet.getCell(1, startColumn + index).value = columnKey;
            });

            const endColumn = existingColumns.length; // Get the ending column index

            sheet.mergeCells(2, startColumn, 2, endColumn); // Merge the cells in the second row for the brand name
            const brandNameCell = sheet.getCell(2, startColumn);
            brandNameCell.value = ` Match/Does Not Match With ${brand}`;
            brandNameCell.alignment = {
              vertical: "middle",
              horizontal: "center",
            };
            brandNameCell.font = { bold: true };
            brandNameCell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "09A5ED" }, // Specify the background color (#238A1D)126224
            };
          });

          //  Top  5 images -----------------------------------------
          const brandNames = [];
          object?.top5Images?.forEach((fiveimages, index) => {
            const startTopFiveIMagesColumn = existingColumns?.length + 1;

            fiveimages?.forEach((image) => {
              const brandName = image.brandName;
              if (!brandNames.includes(brandName)) {
                brandNames.push(brandName);
              }
            });

            for (let i = 1; i <= 5; i++) {
              const brandName = ovreallBrands[index];

              existingColumns.push({
                header: `${i}`,
                key: `${i - 1}_${brandName}`,
              });
            }
            const endColumnTopFiveImages = existingColumns.length; // Get the ending column index

            sheet.mergeCells(
              2,
              startTopFiveIMagesColumn,
              2,
              endColumnTopFiveImages
            ); // Merge the cells in the second row for the brand name
            const brandNameTopFiveIMagesCell = sheet.getCell(
              2,
              startTopFiveIMagesColumn
            );

            const brandDataHeading = ovreallBrands[index];

            brandNameTopFiveIMagesCell.value = `Top 5 Images Of ${brandDataHeading}`;
            brandNameTopFiveIMagesCell.font = { bold: true };
            brandNameTopFiveIMagesCell.alignment = {
              vertical: "middle",
              horizontal: "center",
            };
            brandNameTopFiveIMagesCell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "09A5ED" }, // Specify the background color (#FF94C8E4)126224
            };
          });
          // Top 5images ended ----------------------------------------------
          // explination Question and answers -----------------------------------

          finalData?.expQuestionsBrandCount?.forEach((brandName, index) => {
            let startQuestionColumn = existingColumns.length + 1;
            for (let i = 0; i < brandName.count; i++) {
              const brandNameQuestion = ovreallBrands[index];

              const heading = brandName?.questions?.map((ele) => ele?.name);
              function removeHtmlTags(text) {
                const regex = /<[^>]+>/g;
                return text.replace(regex, "");
              }
              const removeTag = heading[i];
              const responseWithoutTags = removeHtmlTags(removeTag);

              existingColumns.push({
                header: responseWithoutTags, // Use heading as the header
                key: `${i + 1}_${i + 1}_${brandNameQuestion}`,
              });
            }

            const endQuestionsColumn = existingColumns.length; // Get the ending column index

            sheet.mergeCells(2, startQuestionColumn, 2, endQuestionsColumn); // Merge the cells in the second row for the brand name
            const brandDataHeading = ovreallBrands[index];
            const brandNameQuestionCell = sheet.getCell(2, startQuestionColumn);
            brandNameQuestionCell.value = `Explanation Question For ${brandDataHeading}`;
            brandNameQuestionCell.font = { bold: true };
            brandNameQuestionCell.alignment = {
              vertical: "middle",
              horizontal: "center",
            };
            brandNameQuestionCell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "09A5ED" }, // Specify the background color (#FF94C8E4)126224
            };
          });
          // explination Question and answers -----------------------------------
          // comparision Question and answers -----------------------------------
          if (finalData?.studyComparisonQuestionsCount > 0) {
            finalData?.compQuestions?.forEach((brandName, index) => {
              let startQuestionColumn = existingColumns.length + 1;

              for (let i = 0; i < 1; i++) {
                // const brandNameQuestion = ovreallBrands[index];
                const heading = brandName.name;
                function removeHtmlTags(text) {
                  const regex = /<[^>]+>/g;
                  return text.replace(regex, "");
                }
                const removeTag = heading;

                const responseWithoutTags = removeHtmlTags(removeTag);
                existingColumns.push({
                  header: responseWithoutTags, // Use heading as the header
                  key: `${index + 1}_${index + 1}_${index + 1}`,
                });
              }

              const endQuestionsColumn = existingColumns.length; // Get the ending column index

              sheet.mergeCells(2, startQuestionColumn, 2, endQuestionsColumn); // Merge the cells in the second row for the brand name
              // const brandDataHeading = ovreallBrands[index];
              const brandNameQuestionCell = sheet.getCell(
                2,
                startQuestionColumn
              );
              brandNameQuestionCell.value = `Comparison Question`;
              brandNameQuestionCell.font = { bold: true };
              brandNameQuestionCell.alignment = {
                vertical: "middle",
                horizontal: "center",
              };
              brandNameQuestionCell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "09A5ED" }, // Specify the background color (#FF94C8E4)126224
              };
            });
          }

          // comparision Question and answers -----------------------------------
          sheet.columns = existingColumns;

          setLoading(false);
        }
        // -----------------------------------------------------------------------------------------------------
        const rowData = {
          respnumber: object.surveyUser.key,
          firstName: object.surveyUser.firstName,
          lastName: object.surveyUser.lastName,
        };

        object?.imagesSelections?.forEach((brandName) => {
          const images = sortImagesByImageId(brandName);

          images?.forEach((image, index) => {
            const newImageId = image.imageName.replace(".jpg", "");
            const columnKey = `${image.brandName}_${newImageId}_${index + 1}`;
            rowData[columnKey] = image.response;
          });
        });

        object?.top5Images?.forEach((fiveimages, index) => {
          const mappedImages = fiveimages.map((ele, i) => ({
            key: `${i}_${ele.brandName}`,
            image: ele.imageName,
          }));

          mappedImages?.forEach((mappedImage) => {
            const topFiveMappedIMageData = mappedImage.image.replace(
              ".jpg",
              ""
            );
            rowData[mappedImage.key] = topFiveMappedIMageData;
            // Add width and text wrapping to the cell
            // const cell = sheet.getCell(sheet.lastRow.number, mappedImage.key);
          });
        });

        object?.explanationQuestions?.forEach((QuestionGroup, groupIndex) => {
          const mappedQuestions = QuestionGroup.map((ele, i) => ({
            key: `${i + 1}_${i + 1}_${ele.brandName}`,
            response: `${ele.response}`,
          }));
          mappedQuestions?.forEach((mappedQuestion) => {
            const QuestionAndResponse = mappedQuestion.response;
            rowData[mappedQuestion.key] = QuestionAndResponse;
          });
        });
        object?.comparisonQuestions?.forEach((QuestionGroup, groupIndex) => {
          const mappedQuestions = QuestionGroup.response;
          const key = `${groupIndex + 1}_${groupIndex + 1}_${groupIndex + 1}`;

          rowData[key] = mappedQuestions;
        });
        sheet.addRow(rowData);
      });

      // sheet.columns.forEach((column) => {
      //   let maxWidth = 0;

      //   sheet.getColumn(column.key).eachCell({ includeEmpty: true }, (cell) => {
      //     const cellWidth = cell.value ? String(cell.value).length : 0;
      //     maxWidth = Math.max(maxWidth, cellWidth);
      //   });

      //   column.width = Math.min(Math.max(20, maxWidth), 60); // Set minimum width of 20 and maximum width of 60
      //   column.alignment = { wrapText: true };
      // Enable text wrapping
      // });

      sheet?.columns?.forEach((column) => {
        let maxWidth = 0;
        let rowNumber = 0;

        sheet
          .getColumn(column.key)
          .eachCell({ includeEmpty: true }, (cell, rowNumber) => {
            // Skip the second row
            if (rowNumber !== 2) {
              const cellWidth = cell.value ? String(cell.value).length : 0;
              maxWidth = Math.max(maxWidth, cellWidth);
            }
          });

        column.width = Math.min(Math.max(20, maxWidth), 60); // Set minimum width of 20 and maximum width of 60
        column.alignment = {
          wrapText: true,
          vertical: "middle",
        };
      });
      sheet.getRow(1).alignment = {
        horizontal: "center",
        wrapText: true,
      };
      sheet.getRow(2).alignment = {
        horizontal: "center",
        wrapText: true,
      };

      // ---------------All images responses Match\Doesnot Match-------------------
      // ------------------SHEET TWO ICONNECT-----------------------------------------------------------------------------------

      await sheetTwo(finalData, workbook);

      //========================================================================================================================
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = studyHeadingName
          ? `${studyHeadingName}.xlsx`
          : "iConnect.xlxs";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    } else {
      setLoading(false);
    }
  };

  const handleClickReport = () => {
    setLoading(true);
    getStudyReport({ id: ele.id }).then((response) => {
      exportToExcel(response.data);
    });
  };
  return (
    <div>
      {loading ? (
        <div>
          <LoadingOutlined />
        </div>
      ) : (
        <button
          style={{
            border: "none",
            backgroundColor: "white",
            padding: "0px",
          }}
          title="Report"
          onClick={handleClickReport}
        >
          <DescriptionIcon
            sx={{
              fontSize: "19px",
              color: "#565656;",
            }}
          />
        </button>
      )}
    </div>
  );
}
