import React from "react";
import "../Sider/sider.css";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { NavLink } from "react-router-dom";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
export default function SiderComponent() {
  return (
    <div>
      <p className="iconnectHeading">ICONNECT</p>
      <div className="overAllSideList">
        <div className="sideNav">
          <ul style={{ listStyle: "none" }}>
            <div className="bookicon-studies">
              <li className="studies">
                <NavLink to="/StudyList" className="link">
                  <LocalLibraryIcon className="bookicon" />
                  Studies
                </NavLink>
              </li>
            </div>
            <div className="helpicon-questions">
              <li className="instructionslist">
                <NavLink to="/Instructions" className="link">
                  <QuestionAnswerIcon className="TextSnippetIcon" />
                  Study Instructions
                </NavLink>
              </li>
            </div>
            <div className="helpicon-questions">
              <li className="reportslist">
                <NavLink to="/Reports" className="link">
                  <TextSnippetIcon className="TextSnippetIcon" />
                  Reports
                </NavLink>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
}
