import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "../Viewimages/viewimages.css";
import { Button } from "antd";
// --------------------------VIEWIMAGES POPUP--------------------------------------------
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ViewImages(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <button
        className="view_images_btn_contentComponent"
        onClick={handleClickOpen}
      >
        <span style={{ color: "#da8517" }}>View Images</span>
      </button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "690px",
              minHeight: "400px",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{
            background: "#093975",
            color: "white",
            fontFamily: "RaleWay",
            fontWeight: "600",
          }}
        >
          {props?.ele?.name}
        </BootstrapDialogTitle>
        <DialogContent dividers className="AddStudyContainer">
          <div className="grid-container">
            {props.ele.studyImages.map((image) => {
              return (
                <div className="viewImages_background">
                  <img
                    src={image?.images?.url}
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
              );
            })}
          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            className="view_images_closebtn"
          >
            Close
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
}
