const initialState = {
  role: "",
  user_id: "",
  first_name: "",
  login_message: "",
};

export const IcReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "LOGIN_USER_SUCCESS": {
      const newState = { ...state };
      newState.role = payload.role;
      newState.user_id = payload.user_id;
      newState.first_name = payload.parseResponse.data.first_name;
      newState.login_message = true;
      return newState;
    }
    case "LOGIN_USER_FAILED": {
      const newState = { ...state };
      return newState;
    }

    case "LOGOUT_USER_SUCCESS": {
      localStorage.removeItem("role");
      localStorage.removeItem("user_id");
      localStorage.removeItem("first_name");
      return (state = initialState);
    }
    default:
      const newState = { ...state };
      return (state = newState);
  }
};
