import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "../AddStudyDataBtn/addstudybtn.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker } from "antd";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Select, Space } from "antd";
import { brandsData, moderatorData } from "../../studies.js";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useSelector } from "react-redux";
import AddStudyCompTwo from "./AddStudyCompTwo";
library.add(fas);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddStudyData(props) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [moderator, setModerator] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [datata, setDatata] = useState([]);
  const [dateSelected, setDateSelected] = useState(null);

  const [moderatorOptionData, setModeratorOptionData] = useState([]);
  const [moderatorName, setModeratorName] = useState("");
  const [dateSelectedTwo, setDateSelectedTwo] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openAddStudyCompTwo, setOpenAddStudyCompTwo] = useState(false);
  const [childData, setChildData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(8);
  const data = localStorage.getItem("user_id");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setName("");
    setSelectedOption(8);
    setDateSelectedTwo("");
    setModeratorOptionData([]);
    setModeratorName("");
    setDateSelected(null);
    setBrandData([]);
  };

  const inputHandleChange = (event) => {
    setName(event.target.value);
  };

  const moderatorsChange = (event) => {
    setModeratorOptionData(event.target.value);
    const data = moderator.find((a) => a.id == +event.target.value)?.name;
    setModeratorName(data);
  };

  function showToastMessageError(e) {
    toast.error("All Fields Required", {
      position: toast.POSITION.TOP_RIGHT,
    });
    // e.currentTarget.disabled = true;
  }

  // --------------------------------DATEPICKERONE--------------------------------------------
  const dateIcon = (
    <FontAwesomeIcon icon="fa-solid fa-calendar-days" className="dateicon" />
  );

  const onChangeOne = (date) => {
    setDateSelectedTwo(date);
  };
  // --------------------------------DATEPICKERTWO--------------------------------------------

  const onChangeOfDateTwo = (date) => {
    setDateSelected(date);
  };

  // ---------------------------------BRANDS------------------------------------------------

  useEffect(() => {
    const fetchData = async () => {
      const brands_Data = await brandsData();
      if (brands_Data) {
        setDatata(
          brands_Data?.data?.brands?.map((ele) => {
            return {
              name: ele.name,
              id: ele.id,
            };
          })
        );
      }
      const response = await moderatorData();
      if (response) {
        setModerator(
          response?.data?.users?.map((ele) => {
            return {
              name: ele.first_name,
              id: ele.id,
            };
          })
        );
      }
    };
    fetchData();
  }, []);

  // const handleBrandChange = (value) => {
  //   if (value.length <= 3) {
  //     setBrandData(value);
  //     setIsDropdownOpen(false);
  //   } else {
  //     alert("Please select three brands only.");
  //     setBrandData(value.slice(0, 3)); // Keep only the first three values
  //   }
  // };
  const handleBrandChange = (value) => {
    if (value.length <= 3) {
      setBrandData(value);
      setIsDropdownOpen(false);
    } else {
      toast.error("Please add 3 brands only ", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      setBrandData(value.slice(0, 3));
      setIsDropdownOpen(false);
    }
  };

  // const disabledDate = (current) => {
  //   if (dateSelectedTwo && dateSelectedTwo.clone) {
  //     console.log(dateSelectedTwo, "have date two");
  //     return current && current < dateSelectedTwo.clone().add(0, "days");
  //   }
  //   return current && current < moment().startOf("day");
  // };
  const disabledDate = (current) => {
    if (dateSelectedTwo && dateSelectedTwo.clone) {
      if (!dateSelected) {
        return (
          current &&
          (current < moment().startOf("day") ||
            current >= dateSelectedTwo.clone().startOf("day"))
        );
      }
      return (
        current &&
        (current < moment().startOf("day") ||
          current >= dateSelectedTwo.clone().startOf("day") ||
          current < dateSelected.clone().startOf("day"))
      );
    }
    return current && current < moment().startOf("day");
  };
  const disabledDateTwo = (current) => {
    if (!dateSelected) {
      return current && current < moment().startOf("day");
    }
    if (dateSelected && dateSelected.clone) {
      return current && current < dateSelected.clone().add(1, "days");
    }
    return false;
  };

  // --------------------------------------------------------------------------------------------
  const dateOption = [];
  for (let i = 1; i <= 90; i++) {
    dateOption.push({
      value: i.toString(),
      label: `${i}`,
    });
  }

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };
  // -----------------------------------------------FORM SUBMIT --------------------------------

  const formSubmit = (e) => {
    if (
      brandData == "" ||
      name == "" ||
      dateSelected == null ||
      dateSelectedTwo == "" ||
      selectedOption == "" ||
      moderatorName == ""
    ) {
      showToastMessageError(e);
    } else {
      e.currentTarget.disabled = false;
      setOpen(true);
      setOpenAddStudyCompTwo(true);
      setChildData({
        title: name,
        startDate: dateSelected,
        endDate: dateSelectedTwo,
        timeout: parseInt(selectedOption),
        createdBy: data,
        moderatorId: parseInt(moderatorOptionData),
        moderatorName: moderatorName,
        brandNames: brandData,
      });
    }
  };

  const areAllFieldsFilled =
    name !== "" &&
    selectedOption !== "" &&
    dateSelected !== null &&
    dateSelectedTwo !== "" &&
    brandData.length !== 0 &&
    moderatorName.length !== 0;
  // -----------------------------------------------------------------------------------
  return (
    <div>
      <div>
        <div>
          <button
            variant="outlined"
            onClick={handleClickOpen}
            className="addstudybtn"
          >
            + Add Study
          </button>
        </div>
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        // onBackdropClick="false"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "690px",
              height: "70%",
              zIndex: 100,
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{
            background: "#093975",
            color: "white",
            fontFamily: "RaleWay",
            fontWeight: "600",
          }}
        >
          Add Study
        </BootstrapDialogTitle>
        <DialogContent dividers className="AddStudyContainer">
          <div>
            <React.Fragment>
              <div>
                <form>
                  <div>
                    <p className="studyname"> Study Name</p>
                    <input
                      className="addinputField"
                      value={name}
                      onChange={inputHandleChange}
                    />
                  </div>
                  <div>
                    <p className="studyname"> Start Date</p>
                    <DatePicker
                      className="adddataPicker"
                      format="DD/MM/YYYY"
                      suffixIcon={dateIcon}
                      allowClear={false}
                      disabledDate={disabledDate}
                      inputReadOnly={true}
                      onChange={onChangeOfDateTwo}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    />
                  </div>
                  <div>
                    <p className="studyname"> End Date</p>
                    <DatePicker
                      className="adddataPicker"
                      suffixIcon={dateIcon}
                      format="DD/MM/YYYY"
                      allowClear={false}
                      inputReadOnly={true}
                      onChange={onChangeOne}
                      disabledDate={disabledDateTwo}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    />
                  </div>
                  <div>
                    <p className="studyname"> TimeOut ( in seconds)</p>
                    <div className="timeout_add">
                      <Select
                        showSearch
                        style={{
                          width: 200,
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={dateOption}
                        onChange={handleSelectChange}
                        dropdownStyle={{
                          zIndex: 2000,
                        }}
                        value={selectedOption}
                      />
                    </div>
                  </div>
                  <div>
                    <p className="studyname"> Moderator </p>

                    <select
                      value={moderatorOptionData}
                      onChange={moderatorsChange}
                      className="classic"
                      placeholder="Select a moderator"
                    >
                      <option value="" disabled selected></option>
                      {moderator?.map((ele) => {
                        return (
                          <option className="addselect" value={ele.id}>
                            {ele?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <p className="studyname"> Brands </p>
                    <div>
                      <Space
                        direction="vertical"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Select
                          mode="tags"
                          placeholder="Please Select Brand"
                          style={{
                            width: 613,
                            marginLeft: 30,
                            fontSize: "14px",
                          }}
                          onChange={handleBrandChange}
                          dropdownStyle={{
                            zIndex: 2000,
                            fontSize: "14px",
                          }}
                          onDropdownVisibleChange={(open) =>
                            setIsDropdownOpen(open)
                          }
                          open={isDropdownOpen}
                          value={brandData.slice(0, 3)} // Display only the first three selected brands
                        >
                          {datata?.map((brands) => {
                            const disabled =
                              brandData?.length >= 3 &&
                              !brandData?.includes(brands?.name);
                            return (
                              <Select.Option
                                key={brands?.name}
                                disabled={disabled}
                              >
                                {brands?.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Space>
                    </div>
                  </div>
                </form>
              </div>
            </React.Fragment>
          </div>
        </DialogContent>
        <DialogActions>
          <button autoFocus onClick={handleClose} className="addStudyCancelBtn">
            Cancel
          </button>
          <button
            autoFocus
            onClick={formSubmit}
            disabled={!areAllFieldsFilled}
            className="addStudySaveBtn"
          >
            Next
          </button>
        </DialogActions>
      </BootstrapDialog>

      <AddStudyCompTwo
        open={openAddStudyCompTwo}
        data={childData}
        onClose={() => setOpenAddStudyCompTwo(false)}
        getModerator={props.getModerator}
      />
    </div>
  );
}
