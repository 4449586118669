import axios from "axios";
import _ from "lodash";
import swal from "sweetalert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export function ApiUrl(path) {
  return `${process.env.REACT_APP_API_URL}${path}`;
}

export function HttpErrorMessage(error) {
  const status = error.response ? error.response.status : "";

  if (_.get(error, "response.data.error", null)) {
    const errorMessage = error.response.data.error;
    toast.error(errorMessage);
    return {
      message: errorMessage,
      code: status,
    };
  } else if (_.get(error, "response.data", null)) {
    const errorMessage = error.response.data;
    toast.error(errorMessage);
    return {
      message: errorMessage,
      code: status,
    };
  } else {
    const errorMessage = error.message;
    toast.error(errorMessage);
    return {
      message: errorMessage,
      code: status,
    };
  }
}

export function ParseGeneralResponse(success, response, options) {
  const result = { success };
  if (!_.isEmpty(options.return)) {
    result.return = options.return;
  }
  if (success) {
    toast.success(response?.data?.message, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toastMessages",
    });
    const dataKeys = options.responseDataKeys || {};
    _.forEach(dataKeys, (value, key) => {
      const valueKey = _.isEmpty(value) ? "data" : `data.${value}`;
      result[key] = _.get(response, valueKey, null);
    });
  } else {
    result.error = HttpErrorMessage(response);
  }
  return result;
}

export function Call(
  method,
  url,
  data,
  parseCallback,
  options = {},
  authorization
) {
  const dataObj = {
    method,
    url,
    data,
  };
  if (authorization) {
    try {
      dataObj.headers = {
        "client-id": `${process.env.REACT_APP_CLIENT_ID}`,
        "client-secret": `${process.env.REACT_APP_CLIENT_SECRET}`,
      };
    } catch {
      swal("please check your credentials");
    }
  }

  return new Promise((resolve) => {
    axios(dataObj)
      .then((response) => {
        resolve(parseCallback(true, response, options));
      })
      .catch(async (error) => {
        resolve(parseCallback(false, error, options));
      });
  });
}
