import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "../ComparisionQuestions/comparisionquestion.css";
import {
  getComparisonQuestionsAndImages,
  ExplanationQuestionResponse,
} from "../../participants.js";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import netLoader from "../../../images/loading-slow-net.gif";
export default function ComparisionQuestion() {
  const location = useLocation();
  const navigate = useNavigate();
  const { propsData } = location.state || {};
  const [emptyFields, setEmptyFields] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [brandId, setBrandId] = useState([]);
  const [brand, setBrand] = useState();
  const [studyId, setStudyId] = useState("");
  const [comparisonQuestion, setComparisonQuestion] = useState(null);
  const [images, setImages] = useState([]);
  const [textareas, setTextareas] = useState("");
  const user_id_data = sessionStorage.getItem("loginUserId");
  const [comparisonResponse, setComparisonResponse] = useState();

  const [comparisonResponseTwo, setComparisonResponseTwo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (propsData && propsData.length > 0) {
      setIsLoading(true);
      setStudyId(id);
      let brands = [];
      let brandName = [];
      for (let i = 0; i < propsData.length; i++) {
        brands.push({ name: propsData[i].name, id: propsData[i].id });
        brandName.push(propsData[i].name);
      }
      setBrand(brandName);
      setBrandId(brands);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (brandId && id) {
        let images = [];
        let count = 0;
        for (let i = 0; i < brandId.length; i++) {
          setIsLoading(true);
          const response = await getComparisonQuestionsAndImages({
            brandId: brandId[i].id,
            studyId,
            user_id_data,
          });
          if (response) {
            setIsLoading(false);
            if (response.success === true) {
              if (brandId.length === 1) {
                navigate("/logout");
              }
              if (response.data.images.length === 0) {
                if (brandId.length === 3) {
                  count = count + 1;
                  if (count >= 2) {
                    navigate("/logout");
                  } else {
                    images.push({
                      name: brandId[i].name,
                      brandId: brandId[i].id,
                      images: response.data.images,
                    });
                    setIsLoading(false);
                  }
                } else if (brandId.length === 2) {
                  count = count + 1;
                  if (count >= 1) {
                    navigate("/logout");
                  } else {
                    images.push({
                      name: brandId[i].name,
                      brandId: brandId[i].id,
                      images: response.data.images,
                    });
                    setIsLoading(false);
                  }
                } else if (brandId.length === 1) {
                  navigate("/logout");
                }
              } else {
                setComparisonQuestion(response.data.questions);
                images.push({
                  name: brandId[i].name,
                  brandId: brandId[i].id,
                  images: response.data.images,
                });
              }
              setIsLoading(false);
            }
          }
        }
        setImages(images);
      }
    };
    fetchData();
  }, [brandId]);

  const handleChange = (event, id) => {
    setTextareas((prevState) => ({
      ...prevState,
      [id]: event.target.value,
    }));
    const updatedEmptyFields = emptyFields.filter((field) => field !== id);
    if (event.target.value.trim().length === 0) {
      updatedEmptyFields.push(id);
    }
    setEmptyFields(updatedEmptyFields);

    setComparisonResponse(
      {
        text_response: Object.keys(textareas).map((key) => {
          return {
            question_id: Number(key),
            response_text: textareas[key],
          };
        }),
      }.text_response
    );
    setComparisonResponseTwo({
      user_id: parseInt(user_id_data),
      study_id: studyId,
      brandId: null,
      responses: comparisonResponse,
    });
  };

  const comparisionHandleClick = async () => {
    const emptyFields = comparisonQuestion
      .filter((ele) => {
        return !(textareas[ele.id] && textareas[ele.id].trim().length > 0);
      })
      .map((ele) => ele.id);

    if (emptyFields.length > 0) {
      setEmptyFields(emptyFields);
      return;
    }
    const responses = {
      user_id: parseInt(user_id_data),
      study_id: studyId,
      responses: Object.keys(textareas).map((key) => {
        return {
          question_id: Number(key),
          response_text: textareas[key],
        };
      }),
    };
    if (loading == false) {
      setLoading(true);
      const response = await ExplanationQuestionResponse(responses);
      if (response) {
        if (response.success === true) {
          setLoading(false);
          setTextareas({});
          navigate("/logout");
        }
      }
    }

    return;
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="loading-container">
          <img className="img_loader" src={netLoader} />
        </div>
      ) : (
        <div>
          <p className="please_contrast_the">
            Please contrast the image you have of{" "}
            <span className="brands_For_Comparision_Selection">
              {propsData && propsData.length > 0 ? propsData[0].name : ""}
            </span>{" "}
            {propsData &&
              propsData.slice(1).map((brands, index) => (
                <React.Fragment key={index}>
                  {index === propsData.length - 2
                    ? " with the image you have of  "
                    : "and "}
                  <span className="brands_For_Comparision_Selection">
                    {brands.name}
                  </span>
                </React.Fragment>
              ))}
            .
          </p>

          <div>
            {images.map((imag) => {
              if (imag.images.length === 0) {
                return null;
              }
              return (
                <div>
                  <span className="brand_name">{imag.name}</span>
                  <div className="selectOverSelectionComparision">
                    <div className="selectOverSelectionComparisionOne">
                      {imag.images.map((ele) => {
                        return (
                          <div className="adjust_images">
                            <img
                              src={ele.url}
                              width="100%"
                              alt="img not found"
                              className="borderForImages"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            {comparisonQuestion?.map((ele, index) => (
              <div key={index}>
                <div className="question_numberings">
                  <span style={{ color: "red" }}>*</span>
                  <span>{index + 1}.</span>

                  <span
                    style={{
                      maxWidth: "99%",
                      wordWrap: "break-word",
                    }}
                    className="comparison_question_data "
                    dangerouslySetInnerHTML={{ __html: ele.name }}
                  ></span>
                </div>
                <textarea
                  className="comparison_data"
                  value={textareas[ele?.id] || ""}
                  onChange={(event) => {
                    handleChange(event, ele?.id);
                  }}
                />
                <br></br>
                {emptyFields.includes(ele.id) && (
                  <span className="error_message_explination">
                    * Above question is mandatory to answer
                  </span>
                )}
              </div>
            ))}
          </div>
          <button
            onClick={comparisionHandleClick}
            className={
              loading
                ? "comparision_Next_Btn_loading "
                : "comparision_Next_Btn "
            }
          >
            Submit
          </button>
        </div>
      )}
    </React.Fragment>
  );
}
