import React, { useEffect, useState } from "react";
import "../Form/form.css";
import { Form, Input } from "antd";
import { participantLogin } from "../participants.js";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import loadtwo from "../../images/loadtwo.gif";
const validateMessages = {
  required: "${label} is required!",
};
export default function LoginPage() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  let propsData;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { propsData } = location.state || {};
  }, [propsData]);
  const { id } = useParams();
  const studyId = parseInt(id);

  const submitForm = async ({ firstName, lastName, key }) => {
    if (loading === false) {
      setLoading(true);
      const response = await participantLogin({
        firstName,
        lastName,
        key,
        studyId,
      });
      if (response.success == true) {
        setLoading(false);
        sessionStorage.setItem("loginUserId", response.data.data.id);
        sessionStorage.setItem("participant_role", response.data.data.role);
        navigate(`/Instructions/${id}`, {
          state: { propsData: propsData },
        });
      }

      form.resetFields();
    }
  };

  return (
    <div>
      <Form
        form={form}
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 4 }}
        validateMessages={validateMessages}
        onFinish={submitForm}
        className="formContainer"
      >
        <Form.Item
          name="firstName"
          label="First Name"
          className="input"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input className="formInput" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          className="input"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input className="formInput" />
        </Form.Item>
        <Form.Item
          name="key"
          label="Code"
          className="input"
          rules={[
            {
              required: true,
              pattern: /^[0-9]{1,5}$/,
              message: "Please enter a valid code ",
            },
          ]}
        >
          <Input className="formInput" />
        </Form.Item>
        <Form.Item>
          <button
            htmlType="submit"
            className={loading ? "loginButton_loading" : "loginButton"}
          >
            {loading ? (
              <img src={loadtwo} style={{ width: "150px", height: "150px" }} />
            ) : (
              "Next"
            )}
          </button>
        </Form.Item>
      </Form>
    </div>
  );
}
