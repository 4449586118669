import { all, call, put, takeLatest } from "redux-saga/effects";
import { loginModerator } from "../../../studies";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function* login({ payload }) {
  if (payload.loading == false) {
    try {
      const parseResponse = yield call(loginModerator, payload.data);
      if (parseResponse.success && parseResponse.data.data.user_valid) {
        const { role } = parseResponse.data.data;
        const { user_id } = parseResponse.data.data;
        const { first_name } = parseResponse.data.data;
        localStorage.setItem("role", role);
        localStorage.setItem("user_id", user_id);
        localStorage.setItem("first_name", first_name);
        yield put({
          type: "LOGIN_USER_SUCCESS",
          payload: { role, user_id, parseResponse },
        });
        payload.navigate("/StudyList");
      } else {
        toast.error(parseResponse.error.message.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error("Invalid User Credentials!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  }
}

function* logout() {
  yield put({ type: "LOGOUT_USER_SUCCESS" });
}

export function* WatchUser() {
  yield all([takeLatest("LOGIN_USER", login)]);
  yield all([takeLatest("LOGOUT", logout)]);
}
