import React from "react";
import img0 from "../../../images/img0.jpg";
import img1 from "../../../images/img1.jpg";
import img2 from "../../../images/img2.jpg";
import img3 from "../../../images/img3.jpg";
import img4 from "../../../images/img4.jpg";
import img5 from "../../../images/img5.jpg";
import img6 from "../../../images/img6.png";
import "../../SpencerHall/LoginPage/moderatorImages.css";

const ModeratorImages = () => {
  return (
    <div className="carouselContainer">
      <div className="carouselMargin">
        <img src={img3} width="14.285%" alt="img not found" />
        <img src={img1} width="14.285%" alt="img not found" />
        <img src={img5} width="14.285%" alt="img not found" />
        <img src={img6} width="14.285%" alt="img not found" />
        <img src={img2} width="14.285%" alt="img not found" />
        <img src={img0} width="14.285%" alt="img not found" />
        <img src={img4} width="14.285%" alt="img not found" />
      </div>
    </div>
  );
};
export default ModeratorImages;
