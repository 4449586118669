import React, { useState, useEffect } from "react";
import "../../SpencerHall/ReportsComponent/reports.css";
import netloader from "../../../images/loading-slow-net.gif";
import { Table, Result } from "antd";
import { individualUserReport } from "../../studies";
import moment from "moment";
import ReportPdf from "./ReportPdf";
export default function Reports() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  function actionIcons(ele) {
    return (
      <div style={{ display: "flex" }}>
        <ReportPdf ele={ele} />
      </div>
    );
  }
  function getReports() {
    setLoading(true);
    individualUserReport().then((response) => {
      const data = response?.data?.users.map((ele) => {
        return {
          key: ele.id,
          id: ele.id,
          firstName: ele.firstName,
          lastName: ele.lastName,
          dateAndTime: moment(ele.updatedAt).format("DD-MM-YYYY "),
          username: ele.ModeratorName,
          studyname: ele?.study?.name,
          actions: actionIcons(ele),
        };
      });
      setData(data?.reverse());
      setLoading(false);
    });
  }
  useEffect(() => {
    getReports();
  }, []);

  // // ----------------------------------SEARCH FILTER --------------------------------------

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = data?.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(data);
    }
  };

  // ------------------------------------------------------------------------------

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      width: 115,
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      width: 115,
    },
    {
      title: "Date & Time",
      dataIndex: "dateAndTime",
      width: 115,
    },
    {
      title: "Study Name",
      dataIndex: "studyname",
      width: 115,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      width: 70,
    },
  ];

  let locale = {
    emptyText: <Result title=" QuestionList is Empty" />,
  };
  if (loading) {
    return (
      <div>
        <img
          src={netloader}
          alt="loading"
          style={{
            height: "120px",
            width: "120px",
          }}
          className="netloader"
        ></img>
      </div>
    );
  }

  if (data?.length !== 0) {
    return (
      <div>
        <p className="reportslisttag">Reports List</p>
        <div className="tablewidth">
          <div className="searchandaddbtn">
            <div>
              <input
                icon="search"
                placeholder="Search"
                className="searchinput"
                onChange={(e) => searchItems(e.target.value)}
              />
            </div>
          </div>

          {searchInput.length > 1 ? (
            <Table
              columns={columns}
              dataSource={filteredResults}
              pagination={{
                pageSize: 20,
              }}
              scroll={{
                y: 510,
              }}
            />
          ) : (
            <Table
              columns={columns}
              dataSource={data}
              pagination={{
                pageSize: 20,
              }}
              scroll={{
                y: 510,
              }}
            />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <>
        <p className="studieslist">Reports List List</p>
        <div className="tablewidth">
          <Table locale={locale} columns={columns} dataSource={data} />
        </div>
      </>
    );
  }
}
