import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import FinalComponents from "./Components/FinalComponents/FinalComponents";
import FinalHomePage from "./Components/SpencerHall/FinalHomePage/FinalHomePage";
import LayoutComponent from "./Components/SpencerHall/LayoutSpencerHAll/Layout";
import ContentComponent from "./Components/SpencerHall/LayoutSpencerHAll/ContentComponent/ContentComponent";
import { ToastContainer, toast } from "react-toastify";
import ExplinationQuestion from "./Components/LandingPage3/ExplinationQuestions/ExplinationQuestion";
import { Navigate } from "react-router-dom";
import LayoutIconnectComponent from "./Components/Iconnect/LayoutIconnect";
import Reports from "./Components/SpencerHall/ReportsComponent/Reports";
import Private from "./Components/PrivateRoute";
import CarouselSlider from "./Components/LandingPage2/CarouselAndQues/Carousel";
import Thankyou from "./Components/LandingPage3/ThankyouPage/Thankyou";
import Protected from "./Components/ProtectdRoute";
import ComparisionQuestion from "./Components/LandingPage3/ComparisionQuestions/ComparisionQuestion";
import SelectedImages from "./Components/LandingPage3/userSelectedImages/UserSelectedImages";
import AddInstructionInLogin from "./Components/AddInstructions/AddInstructionInLogin";
import ParticipantProtected from "./Components/ParticipantProtectedRoute";
import ParticipantPrivate from "./Components/ParticipantPrivateRoute";
import LoginInstructionsPage from "./Components/LandingPage2/CarouselAndQues/LoginInstructionsPage";

function App() {
  useEffect(() => {
    window.addEventListener("popstate", (e) => {
      window.history.go(1);
    });
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<Protected />}>
            <Route exact path="" element={<Navigate to="/signup" />}></Route>
            <Route exact path="/signup" element={<FinalHomePage />}></Route>
          </Route>

          <Route element={<Private />}>
            <Route exact path="/StudyList" element={<LayoutComponent />}>
              <Route path="" element={<ContentComponent />}></Route>
            </Route>
            <Route path="" element={<LayoutComponent />}>
              <Route exact path="Reports" element={<Reports />}></Route>
            </Route>
            <Route path="" element={<LayoutComponent />}>
              <Route
                exact
                path="Instructions"
                element={<AddInstructionInLogin />}
              ></Route>
            </Route>
          </Route>
          <Route exact path="" element={<LayoutIconnectComponent />}>
            <Route element={<ParticipantProtected />}>
              <Route
                exact
                path=""
                element={<Navigate to="/signIn/:id" />}
              ></Route>

              <Route
                exact
                path="/signIn/:id"
                element={<FinalComponents />}
              ></Route>
            </Route>
            <Route element={<ParticipantPrivate />}>
              <Route
                exact
                path="/Instructions/:id"
                element={<LoginInstructionsPage />}
              ></Route>
              <Route
                exact
                path="/Users/:id"
                element={<CarouselSlider />}
              ></Route>
              <Route
                exact
                path="/select/:id"
                element={<SelectedImages />}
              ></Route>
              <Route
                exact
                path="/explanation/:id"
                element={<ExplinationQuestion />}
              ></Route>
              <Route
                exact
                path="/comparision/:id"
                element={<ComparisionQuestion />}
              ></Route>
              <Route exact path="/logout" element={<Thankyou />}></Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>

      <ToastContainer
        autoOpen={2000}
        autoClose={4000}
        pauseOnFocusLoss={false}
        hideProgressBar={true}
        limit={1}
        style={{ width: "400px" }}
      />
    </div>
  );
}

export default App;
