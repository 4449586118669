import React from "react";
import "../NavBar/navbar.css";
import spencerhallLogo from "../../images/spencerhallLogo.png";
export default function NavBar() {
  return (
    <div>
      <div>
        <div className="navContainer">
          <div className="d-flex">
            <div>
              <img
                className="spencerhallLogo"
                src={spencerhallLogo}
                alt="spencerHallLogo"
                width="300px"
              />
            </div>
            <div style={{ width: "100%" }}>
              <p className="iConnectHead">iConnect</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
