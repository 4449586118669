import React, { useState, useEffect } from "react";
import { Table, Result } from "antd";
import "../ContentComponent/content.css";
import EditComp from "../../Edit/EditComp";
import netloader from "../../../../images/loading-slow-net.gif";
import AddStudyData from "../../AddStudyDataBtn/AddStudyBtn";
import ViewImages from "../../Viewimages/ViewImages";
import DeleteStudy from "../../Delete/DeleteStudy";
import StudyUrl from "../../StudyURL/StudyUrl";
import { myprofile } from "../../../studies";
import { debounce } from "lodash";
import moment from "moment";
import StudyReportBtn from "../../StudyReport/StudyReportBtn";
export default function ContentComponent() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  // // ----------------------------------SEARCH FILTER --------------------------------------

  const debouncedSearchItems = debounce((searchValue) => {
    setSearchInput(searchValue);
    if (searchValue.trim() !== "") {
      setShowSearchIcon(false);
    } else {
      setShowSearchIcon(true);
    }
    if (searchValue !== "") {
      const filteredData = data.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(data);
    }
  }, 100);

  // ------------------------------------------------------------------------------
  function imagesIcon(ele) {
    return <ViewImages ele={ele} />;
  }

  function studyUrl(ele) {
    return <StudyUrl ele={ele} />;
  }

  function actionIcons(ele) {
    return (
      <div style={{ display: "flex" }}>
        <StudyReportBtn ele={ele} />
        <EditComp ele={ele} getModerator={getModerator} />
        <DeleteStudy ele={ele} getModerator={getModerator} />
      </div>
    );
  }

  function getModerator() {
    setLoading(true);
    myprofile().then((response) => {
      const data = response?.data?.data?.map((ele) => {
        return {
          key: ele.id,
          id: ele.id,
          name: ele.name,
          startdate: moment(ele.startDate).format("DD-MM-YYYY "),
          enddate: moment(ele.endDate).format("DD-MM-YYYY "),
          timeout: ele.timeout,
          username: ele.ModeratorName,
          createdBy: 1,
          brandNames: ele.brandNames,
          images: imagesIcon(ele),
          studturl: studyUrl(ele),
          actions: actionIcons(ele),
        };
      });
      setData(data?.reverse());
      setLoading(false);
    });
  }
  useEffect(() => {
    getModerator();
  }, []);

  const columns = [
    {
      title: "Study Name",
      dataIndex: "name",
      width: 115,
    },
    {
      title: "Start Date",
      dataIndex: "startdate",
      width: 115,
    },
    {
      title: "End Date",
      dataIndex: "enddate",
      width: 115,
    },
    {
      title: "Moderator",
      dataIndex: "username",
      width: 115,
    },
    {
      title: "Images",
      dataIndex: "images",
      width: 115,
    },
    {
      title: "Study Url",
      dataIndex: "studturl",
      width: 115,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: 115,
    },
  ];

  let locale = {
    emptyText: <Result title=" StudyList is Empty" />,
  };
  if (loading) {
    return (
      <div>
        <img
          src={netloader}
          alt="loading"
          style={{
            height: "120px",
            width: "120px",
          }}
          className="loader"
        ></img>
      </div>
    );
  }
  if (data?.length !== 0) {
    return (
      <div>
        <p className="studieslist">Studies List</p>
        <div className="tablewidth">
          <div className="searchandaddbtn">
            <div className="search-container">
              <input
                icon="search"
                placeholder="Search"
                className="searchinput"
                onChange={(e) => debouncedSearchItems(e.target.value)}
              />
              {showSearchIcon && (
                <i className="fa fa-search search-icon" aria-hidden="true"></i>
              )}
            </div>
            <div className="container ">
              <div className="d-flex justify-content-end">
                <AddStudyData getModerator={getModerator} />
              </div>
            </div>
          </div>

          {searchInput?.length > 1 ? (
            <Table columns={columns} dataSource={filteredResults} />
          ) : (
            <Table columns={columns} dataSource={data} />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <p className="studieslist">Studies List</p>
        <div className="tablewidth">
          <div className="searchandaddbtn">
            <input
              icon="search"
              placeholder="Search"
              className="searchinput"
              onChange={(e) => debouncedSearchItems(e.target.value)}
            />
            <div className="container ">
              <div className="d-flex justify-content-end">
                <AddStudyData getModerator={getModerator} />
              </div>
            </div>
          </div>
          <Table locale={locale} columns={columns} dataSource={data} />
        </div>
      </div>
    );
  }
}
