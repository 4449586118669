import { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import "../CarouselAndQues/icons.css";
import netloader from "../../../images/loading-slow-net.gif";
import { getStudyImages, BrandSaveImageSelection } from "../../participants";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

function CarouselSlider() {
  const [post, setPost] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { propsData } = location.state || {};
  const { id } = useParams();
  const [showError, setShowError] = useState(false);
  const [timeoutImage, setTimeoutImage] = useState(100);
  const [onLoadTime, setOnLoadTime] = useState(null);
  const handleSelect = (selectedIndex, e) => {
    setCurrentSlide(selectedIndex);
  };
  const loginUserId = sessionStorage.getItem("loginUserId");
  const [selectedOption, setSelectedOption] = useState(null);
  const [brands, setBrands] = useState([]);
  const [finalImages, setFinalImages] = useState(post);
  const [isLoading, setIsLoading] = useState(false);
  const options = [];

  if (brands.length > 0) {
    // Generate options for single brands
    for (let i = 0; i < brands.length; i++) {
      options.push({
        id: [brands[i]?.id],
        name: `Matches  ${brands[i]?.name}`,
      });
      if (brands.length === 1) {
        options.push({
          id: "",
          name: `Does not match  ${brands[i]?.name}`,
        });
      }
    }

    // Generate options for combinations of brands
    for (let i = 0; i < brands.length; i++) {
      for (let j = i + 1; j < brands.length; j++) {
        options.push({
          id: [brands[i]?.id, brands[j]?.id],
          name: `Matches with ${brands[i]?.name} and ${brands[j]?.name}`,
        });
      }
    }
    // Generate options for combinations of three brands
    if (brands.length >= 3) {
      for (let i = 0; i < brands.length; i++) {
        for (let j = i + 1; j < brands.length; j++) {
          for (let k = j + 1; k < brands.length; k++) {
            options.push({
              id: [brands[i]?.id, brands[j]?.id, brands[k]?.id],
              name: "Matches All",
            });
          }
        }
      }
    }
    // Generate option for all brands
    if (brands.length > 1) {
      options.push({
        id: "",
        name: "Does not match for any",
      });
    }
  }

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      setLoading(true);
      const response = await getStudyImages({ id });
      if (response) {
        setPost(response.data.studyImages);
        setFinalImages(response.data.studyImages);
        setBrands(response.data.brands);
        setOnLoadTime(response.data.timeout);
        setLoading(false);
      }
      setTimeout(() => {
        if (post.length === 1) {
          navigate(`/select/${id}`, {
            state: { propsData: brands },
          });
        }
      }, timeoutImage * 1000);
    };

    fetchData();
  }, [timeoutImage * 1000]);

  const onLoad = () => {
    setTimeoutImage(onLoadTime);
  };

  const selectedImages = async () => {
    if (selectedOption === null) {
      setShowError(true);
      return;
    }
    if (post.length === 1 || finalImages.length - 1 === currentSlide) {
      if (isLoading === false) {
        setIsLoading(true);
        const selectedImage = post[currentSlide];
        await BrandSaveImageSelection({
          userId: loginUserId,
          studyImageId: selectedImage.id,
          studyId: id,
          imageUrl: selectedImage.url,
          brandIds: selectedOption.id,
          responseText: selectedOption.name,
        });
        setIsLoading(false);
        navigate(`/select/${id}`, {
          state: { propsData: brands },
        });
        return;
      }
    } else if (selectedOption !== null) {
      const selectedImage = post[currentSlide];
      const remainingImages = [
        ...post.slice(0, currentSlide),
        ...post.slice(currentSlide + 1),
      ];
      setFinalImages(remainingImages);
      if (isLoading == false) {
        setIsLoading(true);
        await BrandSaveImageSelection({
          userId: loginUserId,
          studyImageId: selectedImage.id,
          studyId: id,
          imageUrl: selectedImage.url,
          brandIds: selectedOption.id,
          responseText: selectedOption.name,
        });
        setIsLoading(false);
        const nextSlide = currentSlide % (post.length - 1);
        setPost(remainingImages);
        setCurrentSlide(nextSlide);
        setSelectedOption(null);
        setShowError(false);
      }
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setShowError(false);
  };
  const onClickSlide = (e) => {
    setSelectedOption(null);
    setShowError(false);
    if (e === 0) {
      setPost([]);
      navigate(`/select/${id}`, {
        state: { propsData: brands },
      });
    }
  };

  return (
    <div>
      {loading ? (
        <img
          src={netloader}
          alt="loading"
          style={{
            height: "120px",
            width: "120px",
          }}
          className="loader"
        ></img>
      ) : (
        <div>
          <div className="d-flex">
            <div className="carusel">
              <Carousel
                pause={false}
                onSelect={handleSelect}
                onSlide={(e) => onClickSlide(e, "start")}
                nextIcon={false}
                prevIcon={false}
              >
                {post.map((imageData) => (
                  <Carousel.Item interval={timeoutImage * 1000}>
                    <div className="images_in_carousel_height">
                      <img
                        width="100%"
                        height="100%"
                        src={imageData.url}
                        alt="img not found"
                        className="slider_image"
                        onLoad={onLoad}
                      />
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
            <div className="carouselDynamicOptions">
              <p className="question1">Is this an image that:</p>
              {options.map((option, index) => (
                <div key={index}>
                  <input
                    type="radio"
                    id={option.name}
                    name="options"
                    value={option.name}
                    onClick={(event) => {
                      const radioButton = event.target;

                      if (radioButton.checked) {
                        radioButton.checked = false;
                        setSelectedOption(null);
                      }
                    }}
                    onChange={() => handleOptionSelect(option)}
                    checked={
                      selectedOption && selectedOption.name === option.name
                    }
                  />
                  <label className="label_for_option" htmlFor={option.name}>
                    {option.name}
                  </label>
                </div>
              ))}
              {showError && (
                <p className="error_message_carousel">
                  Please select an option.
                </p>
              )}
              <section className="nextBtnSection">
                <div>
                  <button
                    className={
                      isLoading ? "nextBtnSlider_loading" : "nextBtnSlider"
                    }
                    onClick={selectedImages}
                  >
                    Next
                  </button>
                </div>
              </section>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CarouselSlider;
