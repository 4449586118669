import React, { useState, useEffect } from "react";
import { getStudyImages } from "../../participants";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import netloader from "../../../images/loading-slow-net.gif";
import "../CarouselAndQues/logininstructions.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function LoginInstructionsPage() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let propsData;
  const [instruction, setInstructions] = useState();

  const handleClick = () => {
    navigate(`/Users/${id}`, {
      state: { propsData: propsData },
    });
  };
  useEffect(() => {
    const { propsData } = location.state || {};
    setLoading(true);
    getStudyImages({ id })
      .then((response) => {
        if (response.data.studyInstructions == null) {
          navigate(`/Users/${id}`, {
            state: { propsData: propsData },
          });
        } else {
          setInstructions(response.data.studyInstructions.description);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading ? (
        <img
          src={netloader}
          alt="loading"
          style={{
            height: "120px",
            width: "120px",
          }}
          className="loader"
        ></img>
      ) : (
        <div className="container-fluid ">
          <div
            className="col-md-12 col-sx-12 blackHeading_instruction"
            style={{
              maxWidth: "99%",
              wordWrap: "break-word",
            }}
            //         dangerouslySetInnerHTML={{
            //           __html: `<style>
            //   p {
            //     margin: 0;
            //   }
            // </style>${instruction}`,
            //         }}
          >
            <div className="quill_component">
              <ReactQuill
                value={instruction}
                readOnly
                modules={{ toolbar: false }}
              />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-4">
                <button className="next_btn_instructons" onClick={handleClick}>
                  Continue
                </button>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginInstructionsPage;
