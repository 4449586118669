import { Form, Input } from "antd";
import "../ModeratorLoginForm/moderatorLoginForm.css";
import { useNavigate } from "react-router-dom";
import { loginModerator } from "../../studies";
import "react-toastify/dist/ReactToastify.css";
import { login } from "../../redux/actions/IcAction";
import Iconnect from "../../../images/ICONNECT.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faLock } from "@fortawesome/free-solid-svg-icons";
import { MailOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import loadtwo from "../../../images/loadtwo.gif";

function ModeratorLoginForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    if (loading === false) {
      setLoading(true);
      try {
        const response = await loginModerator({
          email: values.email,
          password: values.password,
        });

        if (
          response.success === true &&
          response.data.data.user_valid === true
        ) {
          setLoading(false);

          localStorage.setItem("role", response.data.data.role);
          localStorage.setItem("user_id", response.data.data.user_id);
          localStorage.setItem("first_name", response.data.data.first_name);
          navigate("/StudyList");
        } else {
          toast.error(response.error.message.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };
  const validateMessages = {
    required: "${label} is required",
    types: {
      email: "please provide a valid email address",
      password: "please provide your password",
    },
  };
  return (
    <div className=" logincontainer">
      <div>
        <img
          src={Iconnect}
          alt="iconnect"
          className="iconnext_image"
          style={{ marginTop: "40px", marginBottom: "30px" }}
        />
      </div>

      <div className="border"></div>

      <div>
        <p className="logintag">LOGIN</p>
        <hr className="hr-border"></hr>
      </div>

      <div className="d-flex justify-content-center">
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          className="form_formWidth"
          onFinish={onFinish}
          autoComplete="off"
          validateMessages={validateMessages}
        >
          <Form.Item
            name={["email"]}
            className="Item"
            rules={[
              {
                type: "email",
                required: true,
              },
            ]}
          >
            <div>
              <Input
                className="username"
                placeholder="Email ID"
                style={{ width: "100%" }}
                prefix={<MailOutlined />}
              />
            </div>
          </Form.Item>

          <Form.Item
            className="Item"
            name="password"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.Password
              className="username"
              placeholder="Password"
              prefix={<FontAwesomeIcon icon={faLock} />}
            />
          </Form.Item>

          <div>
            <button
              className={loading ? "nextButton_loading" : "nextButton"}
              htmlType="submit"
            >
              {loading ? (
                <img
                  src={loadtwo}
                  style={{ width: "150px", height: "150px" }}
                />
              ) : (
                " Login"
              )}
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ModeratorLoginForm;
