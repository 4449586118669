import React from "react";
import HeadingBar from "../HeadingBar/HeadingBar";
import ModeratorImages from "../LoginPage/ModeratorImages";
import ModeratorLoginPage from "../ModeratorLoginForm/ModeratorLoginForm";

export default function FinalHomePage() {
  return (
    <div>
      <HeadingBar />
      <ModeratorImages />
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <ModeratorLoginPage />
        </div>
      </div>
    </div>
  );
}
