import React, { useEffect, useState } from "react";
import { Table, Result } from "antd";
import BtnForAddInstruction from "./AddbtnInstructions";
import EditInstrucionComponent from "./EditInstructions";
import { instructionsGetList, studyNameList } from "../instructions";
import netloader from "../../images/loading-slow-net.gif";
import PreveiwInstruction from "./PreviewInstruction";
import DeleteInstruction from "./DeleteInstruction";
export default function AddInstructionInLogin() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [studyOption, setStudyOption] = useState();

  function getInstructions() {
    setLoading(true);
    Promise.all([studyNameList(), instructionsGetList()])
      .then(([studyResponse, instructionsResponse]) => {
        const studyOptionUpdate = studyResponse?.data?.data;

        const data = instructionsResponse?.data?.data?.map((element) => {
          return {
            key: element.id,
            id: element.studyId,
            studyname:
              studyOptionUpdate?.find((ele) => ele.id === element.studyId)
                ?.name || "",
            preview: preview(element, studyOptionUpdate),
            actions: actionIcons(element, studyOptionUpdate),
          };
        });
        setData(data?.reverse());
        setStudyOption(studyOptionUpdate);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }
  function preview(element, studyOptionUpdate) {
    return (
      <PreveiwInstruction
        element={element}
        studyOptionUpdate={studyOptionUpdate}
      />
    );
  }
  function actionIcons(element, studyOptionUpdate) {
    return (
      <div style={{ display: "flex" }}>
        <EditInstrucionComponent
          element={element}
          studyOptionUpdate={studyOptionUpdate}
          getInstructions={getInstructions}
        />
        <DeleteInstruction
          element={element}
          getInstructions={getInstructions}
        />
      </div>
    );
  }

  useEffect(() => {
    getInstructions();
  }, []);

  // // ----------------------------------SEARCH FILTER --------------------------------------

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchValue.trim() !== "") {
      setShowSearchIcon(false);
    } else {
      setShowSearchIcon(true);
    }
    if (searchInput !== "") {
      const filteredData = data?.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(data);
    }
  };

  // ------------------------------------------------------------------------------

  const columns = [
    {
      title: "Study Name",
      dataIndex: "studyname",
      width: 85,
    },
    {
      title: "View Instructions",
      dataIndex: "preview",
      width: 85,
    },

    {
      title: "Actions",
      dataIndex: "actions",
      width: 30,
    },
  ];

  let locale = {
    emptyText: <Result title=" Instructions list  is Empty" />,
  };
  if (loading) {
    return (
      <div>
        <img
          src={netloader}
          alt="loading"
          style={{
            height: "120px",
            width: "120px",
          }}
          className="net_loader"
        ></img>
      </div>
    );
  }

  if (data?.length !== 0) {
    return (
      <div>
        <p className="instruction_listtag">Instructions</p>
        <div className="table_width">
          <div className="search_and_addbtn">
            <div className="search-container-instruction">
              <input
                icon="search"
                placeholder="Search"
                className="searchinput"
                onChange={(e) => searchItems(e.target.value)}
              />
              {showSearchIcon && (
                <i
                  className="fa fa-search search-icon_instruction"
                  aria-hidden="true"
                ></i>
              )}
            </div>
            <div className="container ">
              <div className="d-flex justify-content-end">
                <BtnForAddInstruction getInstructions={getInstructions} />
              </div>
            </div>
          </div>

          {searchInput?.length > 1 ? (
            <Table
              columns={columns}
              dataSource={filteredResults}
              pagination={{
                pageSize: 20,
              }}
              // scroll={{
              //   y: 510,
              // }}
            />
          ) : (
            <Table
              columns={columns}
              dataSource={data}
              pagination={{
                pageSize: 20,
              }}
              // scroll={{
              //   y: 510,
              // }}
            />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div>
          <p className="instruction_listtag">Instructions</p>
          <div className="table_width">
            <div className="search_and_addbtn">
              <input
                icon="search"
                placeholder="Search"
                className="searchinput"
                onChange={(e) => searchItems(e.target.value)}
              />

              <div className="container ">
                <div className="d-flex justify-content-end">
                  <BtnForAddInstruction getInstructions={getInstructions} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table_width">
          <Table locale={locale} columns={columns} dataSource={data} />
        </div>
      </>
    );
  }
}
