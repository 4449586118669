import * as React from "react";
import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "../AddInstructions/instructions.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { brandsData } from "../studies.js";
import { addInstruction, studyNameList } from "../instructions";
library.add(fas);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function BtnForAddInstruction({ getInstructions }) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState("");
  const [studyOption, setStudyOption] = useState();
  const [optionData, setOptionData] = useState();
  const reactQuillRef = useRef();
  const [optionSelected, setOptionSelected] = useState();
  const [dataSubmit, setDataSubmit] = useState();
  const [loading, setLoading] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setContent("");
    setOptionData();
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await studyNameList();
      if (response) {
        setStudyOption(response?.data?.data);
      }
    };
    fetchData();
  }, []);

  const instructionFormSubmit = async () => {
    if (loading === false) {
      setLoading(true);
      const response = await addInstruction({
        description: content,
        studyId: optionSelected,
      });
      if (response) {
        setLoading(false);
        if (response.success === true) {
          getInstructions();
        }
      }
    }
  };

  const handleChange = (value) => {
    setContent(value);
  };

  const optionChange = (event) => {
    setOptionData(event.target.value);
    const data = studyOption.find((a) => a.id == +event.target.value)?.id;
    setOptionSelected(data);
  };

  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ color: [] }, { background: [] }],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ size: ["small", false, "large", "huge"] }],
  ];

  return (
    <div>
      <div>
        <div style={{ marginLeft: "80px" }}>
          <button
            variant="outlined"
            onClick={handleClickOpen}
            className="addinstructionbtn"
          >
            + Add Instructions
          </button>
        </div>
      </div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "790px",
              minHeight: "600px",
              zIndex: 100,
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{
            background: "#093975",
            color: "white",
            fontFamily: "RaleWay",
            fontWeight: "600",
          }}
        >
          Add Instruction
        </BootstrapDialogTitle>
        <DialogContent dividers className="AddStudyContainer">
          <div>
            <form>
              <div>
                <div>
                  <p className="study_instruction_name"> Select Study</p>
                  <select
                    className="instruction_options"
                    onChange={optionChange}
                    value={optionData}
                  >
                    <option value="" disabled selected></option>
                    {studyOption &&
                      studyOption.map((ele) => {
                        return <option value={ele?.id}>{ele?.name}</option>;
                      })}
                  </select>
                </div>
                <div>
                  <div>
                    <p className="study_instruction_name"> Add Instruction </p>

                    <ReactQuill
                      className="react_quill_container"
                      ref={reactQuillRef}
                      value={content}
                      onChange={handleChange}
                      modules={{ toolbar: toolbarOptions }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            autoFocus
            onClick={handleClose}
            className="addInsructionCancelBtn"
          >
            Cancel
          </button>
          <button
            autoFocus
            onClick={instructionFormSubmit}
            className={
              loading
                ? "addInstructionSaveBtn_loading"
                : "addInstructionSaveBtn"
            }
          >
            Save
          </button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
