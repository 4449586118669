import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from "redux";
import { IcReducer } from "./reducers/IcRedux";
import createSagaMiddleware from "@redux-saga/core";
import { WatchUser } from "./reducers/saga/IcSaga";
import { composeWithDevTools } from "redux-devtools-extension";
const sagaMiddleWare = createSagaMiddleware();
export const Store = createStore(
  IcReducer,
  compose(applyMiddleware(sagaMiddleWare))
);

sagaMiddleWare.run(WatchUser);
