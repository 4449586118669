import * as React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import "../Edit/edit.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select, Space } from "antd";
import { brandsData, moderatorData } from "../../studies.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { format } from "date-fns";
import EditCompTwo from "./EditCompTwo";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EditComp(props) {
  const id = props.ele.id;
  const [name, setName] = useState(props?.ele?.name);
  const [editModerator, setEditModerator] = useState(props?.ele?.moderatorId);
  const [open, setOpen] = React.useState(false);
  const [openEditStudyCompTwo, setOpenEditStudyCompTwo] = useState(false);
  const [childEditData, setEditChildData] = useState(null);
  const selected = props.ele.timeout;
  const [copySuccess, setCopySuccess] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dateSelected, setDateSelected] = useState(props?.ele?.startDate);
  const [dateSelectedTwo, setDateSelectedTwo] = useState(props?.ele?.endDate);
  const [selectedOption, setSelectedOption] = useState(props?.ele?.timeout);
  const editImages = props.ele?.studyImages?.map((ele) => {
    return ele.images;
  });

  const questions = props?.ele?.questions;

  const explination_question = questions?.filter((ele) => ele?.type == 1) || [];
  const comparision_question = questions?.filter((ele) => ele?.type == 2) || [];

  const [mapBrandData, setMapBrandData] = useState([]);
  const [brandData, setBrandData] = useState(
    props?.ele?.studyBrands?.map((ele) => ele?.brands?.name)
  );
  const [moderatorName, setModeratorName] = useState(props?.ele?.ModeratorName);
  const [mapModerators, setMapModerators] = useState([]);

  const dateOption = [];
  for (let i = 1; i <= 90; i++) {
    dateOption.push({
      value: i.toString(),
      label: `${i}`,
    });
  }
  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };
  const handleClickOpen = () => {
    setOpen(true);

    brands();
  };
  const handleClose = () => {
    setOpen(false);
    setName(props?.ele?.name);
    setEditModerator(props?.ele?.moderatorId);
    setDateSelected(props?.ele?.startDate);
    setDateSelectedTwo(props?.ele?.endDate);
    setSelectedOption(props?.ele?.timeout);
    setBrandData(props?.ele?.studyBrands?.map((ele) => ele?.brands?.name));
  };

  const inputEditHandleChange = (event) => {
    setName(event.target.value);
  };

  const moderatorsEditChange = (event) => {
    setEditModerator(event.target.value);
    const data = mapModerators.find((a) => a.id == +event.target.value)?.name;
    setModeratorName(data);
  };

  // --------------------------------DATEPICKERONE--------------------------------------------
  const dateIcon = (
    <FontAwesomeIcon icon="fa-solid fa-calendar-days" className="dateicon" />
  );

  const onChange = (date) => {
    setDateSelected(date);
  };
  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };
  // --------------------------------DATEPICKERTWO--------------------------------------------

  const onChangeOfDateTwo = (date) => {
    setDateSelectedTwo(date);
  };
  const disabledDateTwo = (current) => {
    return current && current < moment(dateSelected).startOf("day");
  };

  // ----------------------------------------------------------------------------------------

  //  --------------------------------------Brands-----------------------------------

  const handleEditBrandChange = (value) => {
    if (value.length <= 3) {
      setBrandData(value);
      setIsDropdownOpen(false);
    } else {
      toast.error("Please add 3 brands only ", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      setBrandData(value.slice(0, 3));
    }
  };
  function brands() {
    brandsData().then((response) => {
      setMapBrandData(
        response.data.brands.map((ele) => {
          return {
            name: ele.name,
            id: ele.id,
          };
        })
      );
    });
    moderatorData().then((response) => {
      setMapModerators(
        response?.data?.users.map((ele) => {
          return {
            name: ele.first_name,
            id: ele.id,
          };
        })
      );
    });
  }
  const handleCopy = () => {
    const textField = document.createElement("textarea");
    textField.value = props.ele.url;
    document.getElementById("container").appendChild(textField);
    textField.select();
    navigator.clipboard.writeText(textField.value).then(() => {
      setCopySuccess(true);
      textField.remove();
      setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    });
  };

  // ----------------------------------------------------------------------------------

  const editFormSubmit = () => {
    setOpen(true);
    setOpenEditStudyCompTwo(true);
    setEditChildData({
      studyId: id,
      title: name,
      startDate: dateSelected,
      endDate: dateSelectedTwo,
      timeout: selectedOption,
      createdBy: 1,
      moderatorId: parseInt(editModerator),
      moderatorName: moderatorName,
      brandNames: brandData,
      copySuccess: copySuccess,
      mapBrandData: mapBrandData,
      questions: explination_question,
      comparision_question: comparision_question,
    });
  };
  const areAllFieldsFilled =
    name !== "" &&
    selected !== "" &&
    editModerator !== "" &&
    dateSelected !== "" &&
    dateSelectedTwo !== "" &&
    brandData.length !== 0;

  // ------------------------------------------------------------------------------------------
  return (
    <div>
      <button
        variant="outlined"
        onClick={handleClickOpen}
        title="Edit"
        style={{ border: "none", background: "none", marginTop: "1.5px" }}
      >
        <FontAwesomeIcon
          icon="fa-solid fa-pen-to-square"
          style={{
            fontSize: "16px",
            color: "#565656",
          }}
        />
      </button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "690px",
              height: "70%",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{
            background: "#093975",
            color: "white",
            fontFamily: "RaleWay",
            fontWeight: "600",
          }}
        >
          Edit Study
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          className="AddStudyContainer"
          key={props.ele.id}
        >
          <div>
            <form>
              <div>
                <p className="studyname"> Study Name</p>
                <input
                  className="addinputField"
                  name="title"
                  value={name}
                  onChange={(e) => inputEditHandleChange(e)}
                />
              </div>
              <div>
                <p className="studyname"> Start Date</p>

                <DatePicker
                  className="adddataPicker"
                  suffixIcon={dateIcon}
                  name="datePickerone"
                  type="date"
                  onChange={onChange}
                  disabledDate={disabledDate}
                  allowClear={false}
                  format="DD/MM/YYYY"
                  inputReadOnly={true}
                  value={dayjs(dateSelected, format)}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                />
              </div>
              <div>
                <p className="studyname"> End Date</p>
                <DatePicker
                  className="adddataPicker"
                  suffixIcon={dateIcon}
                  allowClear={false}
                  inputReadOnly={true}
                  disabledDate={disabledDateTwo}
                  format="DD/MM/YYYY"
                  value={dayjs(dateSelectedTwo, format)}
                  onChange={onChangeOfDateTwo}
                  name="datepickertwo"
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                />
              </div>
              <div>
                <p className="studyname"> TimeOut ( in seconds)</p>
                <div className="timeout_edit">
                  <Select
                    showSearch
                    style={{
                      width: 200,
                    }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={dateOption}
                    onChange={handleSelectChange}
                    dropdownStyle={{
                      zIndex: 2000,

                      fontSize: "14px",
                    }}
                    value={selectedOption}
                  />
                </div>
              </div>

              <div>
                <p className="studyname"> Moderator </p>
                <select
                  onChange={(e) => moderatorsEditChange(e)}
                  value={editModerator}
                  className="classic"
                  name="moderator"
                >
                  {mapModerators.map(({ id, name }) => (
                    <option className="addselect" value={id}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <p className="studyname">Study Url</p>

                <div className="url_study" onClick={handleCopy}>
                  <label htmlFor="text-for-copy-url">
                    <FontAwesomeIcon
                      icon="fa-solid fa-copy"
                      className="copyIcon"
                    />
                  </label>

                  <input
                    id="text-for-copy-url"
                    className="addinputField"
                    type="text"
                    value={props.ele.url}
                    readOnly
                  />
                  {copySuccess && <div className="copysuccess">Copied</div>}
                </div>
                <div id="container" style={{ display: "none" }}></div>
              </div>

              <div>
                <p className="studyname"> Brands </p>
                <div>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Select
                      mode="tags"
                      placeholder="Please select Brand"
                      style={{
                        width: 613,
                        marginLeft: 30,
                        fontSize: "14px",
                      }}
                      value={brandData.map((ele) => {
                        return ele;
                      })}
                      onChange={handleEditBrandChange}
                      dropdownStyle={{
                        zIndex: 2000,
                        height: 100,
                        fontSize: "14px",
                        overflowY: "scroll",
                      }}
                      onDropdownVisibleChange={(open) =>
                        setIsDropdownOpen(open)
                      }
                      open={isDropdownOpen}
                    >
                      {mapBrandData.map((brands) => {
                        const disabled =
                          brandData.length >= 3 &&
                          !brandData.includes(brands.name);
                        return (
                          <Select.Option key={brands.name} disabled={disabled}>
                            {brands.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Space>
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            autoFocus
            onClick={handleClose}
            className="editStudyCancelBtn"
          >
            Cancel
          </button>
          <button
            autoFocus
            onClick={editFormSubmit}
            disabled={!areAllFieldsFilled}
            className="editStudySaveBtn"
          >
            Next
          </button>
        </DialogActions>
      </BootstrapDialog>
      <EditCompTwo
        open={openEditStudyCompTwo}
        data={childEditData}
        onClose={() => setOpenEditStudyCompTwo(false)}
        editImages={editImages}
        getModerator={props.getModerator}
      />
    </div>
  );
}
