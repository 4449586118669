import React from "react";
import { Layout, Space } from "antd";
import SiderComponent from "./Sider/SiderComponent";
import HeaderComponent from "./Header/HeaderComponent";
import { Outlet } from "react-router-dom";
import "../LayoutSpencerHAll/layout.css";
const { Header, Sider, Content } = Layout;

const headerStyle = {
  height: 84,

  backgroundColor: "#E5E5E5",
  border: "2px solid #D8D8D8",
};
const contentStyle = {
  backgroundColor: "#E5E5E5",
};
const siderStyle = {
  color: "#fff",
  backgroundColor: "#112F54",
};

const LayoutComponent = () => (
  <Space
    direction="vertical"
    style={{
      width: "100%",
    }}
    size={[0, 48]}
  >
    <Layout style={{ height: "100vh" }}>
      <Sider style={siderStyle} width={270}>
        <SiderComponent />
      </Sider>
      <Layout>
        <Header style={headerStyle}>
          <HeaderComponent />
        </Header>
        <Content style={contentStyle}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  </Space>
);
export default LayoutComponent;
