import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import DeleteIcon from "@mui/icons-material/Delete";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "../Delete/deleteStudy.css";
import { deleteStudy } from "../../studies.js";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function DeleteStudy(props) {
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleDeleteOpen = () => {
    setOpened(true);
  };

  const handleDeleteClose = () => {
    setOpened(false);
  };

  const handleDelete = async () => {
    // setLoading(true);
    if (loading == false) {
      try {
        setLoading(true);
        const response = await deleteStudy(props.ele.id);
        if (response.success) {
          setLoading(false);
          props.getModerator();
        }
      } catch {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <button
        style={{
          border: "none",
          backgroundColor: "white",
          padding: "0px",
        }}
        title="Delete"
        onClick={handleDeleteOpen}
      >
        <DeleteIcon
          sx={{
            fontSize: "20px",
            color: "#565656",
          }}
          style={{ marginTop: "2px" }}
        />
      </button>
      <BootstrapDialog
        onClose={handleDeleteClose}
        aria-labelledby="customized-dialog-title"
        open={opened}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "671px",
              minHeight: "380px",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleDeleteClose}
          sx={{
            background: "#093975",
            color: "white",
            fontFamily: "RaleWay",
            fontWeight: "600",
          }}
        >
          Delete Study
        </BootstrapDialogTitle>
        <DialogContent dividers className="AddStudyContainer">
          <div>
            <div className="row">
              <div className="col-lg-5"></div>
              <div className="col-lg-2">
                <ExclamationCircleOutlined
                  style={{
                    fontSize: "50px",
                    marginTop: "35px",
                    marginBottom: "20px",
                  }}
                />
              </div>
              <div className="col-lg-5"></div>
            </div>

            <p className="areYou">
              Are you sure you want to permanently delete this Study?{" "}
            </p>
            <p className="onceCompleted">
              Once completed, the action can not be undone.
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            autoFocus
            onClick={handleDeleteClose}
            className="deleteStudyCancelBtn"
          >
            Cancel
          </button>
          <button
            autoFocus
            onClick={handleDelete}
            className={
              loading ? "deleteStudySaveBtn_loading" : "deleteStudySaveBtn"
            }
          >
            Yes,Delete
          </button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
