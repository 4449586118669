import * as React from "react";
import PropTypes from "prop-types";
import { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../AddInstructions/editinstructions.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { editINstructions, studyNameList } from "../instructions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { load } from "config-json";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EditInstrucionComponent({
  element,
  getInstructions,
  studyOptionUpdate,
}) {
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = useState(element.description);
  const reactQuillRef = useRef();
  const [optionData, setOptionData] = useState(element.studyId);
  const [loading, setLoading] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setContent(element.description);
    setOptionData(element.studyId);
  };
  const handleChange = (value) => {
    setContent(value);
  };

  const handleNext = async () => {
    if (loading === false) {
      setLoading(true);
      const response = await editINstructions({
        instructionId: element.id,
        description: content,
        studyId: optionData,
      });
      if (response) {
        setLoading(false);
        if (response.success === true) {
          getInstructions();
        }
      }
    }
  };

  const optionChange = (event) => {
    // setOptionData(event.target.value);
    // const data = studyOptionUpdate.find((a) => a.id == +event.target.value)?.id;

    setOptionData(event.target.value);
  };
  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ color: [] }, { background: [] }],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ align: [] }],
  ];

  // ------------------------------------------------------------------------------------------
  return (
    <div>
      <button
        variant="outlined"
        onClick={handleClickOpen}
        style={{ border: "none", background: "none" }}
      >
        <FontAwesomeIcon
          icon="fa-solid fa-pen-to-square"
          style={{
            fontSize: "16px",
            color: "#565656",
            marginTop: "3px",
          }}
        />
      </button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "790px",
              minHeight: "600px",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{
            background: "#093975",
            color: "white",
            fontFamily: "RaleWay",
            fontWeight: "600",
          }}
        >
          Edit An Instruction
        </BootstrapDialogTitle>
        <DialogContent dividers className="AddInstructionContainer">
          <div>
            <form>
              <form>
                <div>
                  <p className="edit_instruction_name"> Select Study</p>
                  <select
                    className="edit_instruction_options"
                    onChange={optionChange}
                    value={optionData}
                  >
                    <option value="" disabled selected></option>
                    {studyOptionUpdate &&
                      studyOptionUpdate.map((ele) => {
                        return <option value={ele?.id}>{ele?.name}</option>;
                      })}
                  </select>
                  <div>
                    <div>
                      <p className="edit_instruction_name">
                        {" "}
                        Edit Instruction{" "}
                      </p>

                      <ReactQuill
                        className="react_quilledit_container"
                        ref={reactQuillRef}
                        value={content}
                        onChange={handleChange}
                        modules={{ toolbar: toolbarOptions }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            autoFocus
            onClick={handleClose}
            className="editSInstrictionCancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={handleNext}
            autoFocus
            className={
              loading
                ? "editInstructionSaveBtn_loading"
                : "editInstructionSaveBtn"
            }
          >
            Save
          </button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
